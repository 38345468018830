import React, { useState, useEffect } from 'react';
import AreaSelect from './areaSelect.js';
import { useRecoilState } from 'recoil';
import { pointsFilterState, pointsState } from '../state.js';
import { useListPoints } from '../hooks/point';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { pointSort } from '../schema.js';
import { BiFilterAlt } from 'react-icons/bi';

function Filter(_props) {
  const [filter, setFilter] = useRecoilState(pointsFilterState);
  const [pointsData, setPointsData] = useListPoints();
  const pointsLoading = pointsData.loading;
  const pointsError = pointsData.error;

  return (
    <Card>
      <Card.Header>
        <BiFilterAlt className="icon in-card-header" /> Filter
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group controlId="points-filter-form">
            <Container>
              <Row>
                <Col sm="12" md="4" lg="4">
                  <Form.Label className="mt-3">Titel</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={filter.title || ''}
                    onChange={(event) => {
                      setFilter({ ...filter, title: event.target.value });
                    }}
                  />
                </Col>
                <Col sm="12" md="4" lg="4">
                  <Form.Label className="mt-3">Level</Form.Label>
                  <Form.Control
                    type="number"
                    onChange={(event) => setFilter({ ...filter, level: event.target.value })}
                    min={0}
                    max={8}
                  />
                </Col>
                <Col sm="12" md="4" lg="4">
                  <Form.Label className="mt-3">Gebiet</Form.Label>
                  <AreaSelect selectAll value={filter.area} setArea={(a) => setFilter({ ...filter, area: a })} />
                </Col>
                <Col sm="12" md="4" lg="4">
                  <Form.Label className="mt-3">Online Status</Form.Label>
                  <Dropdown
                    onSelect={(v) => {
                      setFilter({ ...filter, online: v === null ? null : v === 'true' ? true : false });
                    }}
                  >
                    <Dropdown.Toggle variant="outline-secondary" id="point-list-sort-dropdown">
                      {filter.online === null ? 'On- & Offline' : filter.online === true ? 'Online' : 'Offline'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        key="online-offline-status-filter-item-both"
                        eventKey={null}
                        active={filter.online === null}
                      >
                        {'On- & Offline'}
                      </Dropdown.Item>
                      <Dropdown.Item
                        key="online-offline-status-filter-item--online"
                        eventKey={true}
                        active={filter.online === true}
                      >
                        Online
                      </Dropdown.Item>
                      <Dropdown.Item
                        key="online-offline-status-filter-item-offline"
                        eventKey={false}
                        active={filter.online === false}
                      >
                        Offline
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col sm="12" md="4">
                  <Form.Label className="mt-3">Sortierung</Form.Label>
                  <Dropdown onSelect={(v) => setFilter({ ...filter, online: !filter.online })}>
                    <Dropdown.Toggle variant="outline-secondary" id="point-list-sort-dropdown">
                      {pointSort[filter.order] || 'Sortierung wählen'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {Object.keys(pointSort).map((k) => (
                        <Dropdown.Item key={k} eventKey={k} active={k === filter.order}>
                          {pointSort[k]}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col sm="12" md="4">
                  <Form.Label className="mt-3">Suche starten</Form.Label>
                  <Button
                    variant="primary"
                    className="w-100"
                    onClick={() => setPointsData({ ...pointsData, reload: true })}
                    disabled={pointsLoading}
                  >
                    {pointsLoading ? 'Laden...' : 'Suchen'}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default Filter;
