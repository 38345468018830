import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useListCategories } from './../hooks/category.js';
import Spinner from 'react-bootstrap/Spinner';

export default function CategorySelect(props) {
  const { fullWidth, value, setCategory, selectAll, mandatory } = props;
  const [categoriesState] = useListCategories();
  const categories = categoriesState.categories;
  const loading = categoriesState.loading;
  const error = categoriesState.error;

  return (
    <Dropdown onSelect={(v) => setCategory(v)}>
      <Dropdown.Toggle
        variant={error ? 'danger' : mandatory && !value ? 'outline-danger' : 'outline-secondary'}
        id="category-select-dropdown"
        style={{ width: fullWidth ? '100%' : undefined }}
      >
        {loading ? (
          <Spinner animation="border" role="status" size="sm">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : error ? (
          'Fehler beim Laden der Kategorien '
        ) : (
          value || 'Kategorie'
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {selectAll && (
          <Dropdown.Item key={'category-all'} eventKey={undefined} active={value === undefined}>
            {'keine'}
          </Dropdown.Item>
        )}
        {categories &&
          categories.map((category, n) => (
            <Dropdown.Item key={category.title + n} eventKey={category.title} active={category.title === value}>
              {category.title}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
