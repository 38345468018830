import React, { useRef } from 'react';
import { Map, TileLayer, Marker } from 'react-leaflet';
import Control from 'react-leaflet-control';
import 'leaflet/dist/leaflet.css';
import { BiCurrentLocation, BiError } from 'react-icons/bi';
import Spinner from 'react-bootstrap/Spinner';
import useLocation from '../hooks/location.js';
import { pointsFilterState, uiState } from '../state.js';
import { useRecoilState } from 'recoil';
import { useListPoints } from './../hooks/point';
import WaypointMarkerIcon from './../img/waypoint-marker-icon.svg';
import L from 'leaflet';
import { useHistory } from 'react-router-dom';

export default function PointsMap() {
  const history = useHistory();
  const mapRef = useRef(null);
  const [uiData, setUiData] = useRecoilState(uiState);
  const [pointsData, setPointsData] = useListPoints();
  const [filterData, setFilterData] = useRecoilState(pointsFilterState);
  const setMapView = (coords, zoom) => {
    if (!mapRef.current) return;
    mapRef.current.leafletElement.setView(coords, zoom);
  };
  const [setLocationBegin, locationLoading, locationError] = useLocation((v) => setMapView(v, 16));
  const points = JSON.parse(JSON.stringify(pointsData)).points;

  const leafletQuizMarkerIcon = new L.Icon({
    iconUrl: WaypointMarkerIcon,
    iconRetinaUrl: WaypointMarkerIcon,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconAnchor: [10, 10],
    iconSize: new L.Point(20, 20),
    className: 'leaflet-div-icon',
  });

  const setCoordBounds = () => {
    if (mapRef.current) {
      const mapBounds = mapRef.current.leafletElement.getBounds();
      setFilterData({
        ...filterData,
        neLat: mapBounds._northEast.lat,
        neLng: mapBounds._northEast.lng,
        swLat: mapBounds._southWest.lat,
        swLng: mapBounds._southWest.lng,
        offset: 0,
      });
      setPointsData({ ...pointsData, reload: true });

      const center = mapRef.current.leafletElement.getCenter();
      setUiData({ ...uiData, mapCenter: [center.lat, center.lng], mapZoom: mapRef.current.leafletElement.getZoom() });
    }
  };

  return (
    <Map
      ref={mapRef}
      center={uiData.mapCenter || [53.56017, 9.996472]}
      zoom={uiData.mapZoom || 11}
      zoomControl={true}
      maxZoom={19}
      attributionControl={true}
      doubleClickZoom={true}
      scrollWheelZoom={true}
      dragging={true}
      onZoomEnd={() => setCoordBounds()}
      onMoveEnd={() => setCoordBounds()}
      animate={false}
      easeLinearity={3}
      whenReady={() => setCoordBounds()}
      attribution=""
      style={{ height: '700px', width: '100%', borderRadius: 3, overflow: 'hidden' }}
    >
      <Control position="topleft">
        <div
          className="leaflet-bar leaflet-custom-layers-control-element bg-light p-1"
          style={{ marginBottom: 10, cursor: 'pointer' }}
        >
          {locationError ? (
            <BiError style={{ width: 22, height: 22 }} onClick={() => setLocationBegin(true)} />
          ) : locationLoading ? (
            <Spinner animation="border" style={{ width: 22, height: 22, margin: 0, padding: 0 }} size="sm" />
          ) : (
            <BiCurrentLocation style={{ width: 22, height: 22 }} onClick={() => setLocationBegin(true)} />
          )}
        </div>
      </Control>

      {points &&
        points.map((point) => (
          <Marker
            key={`map-point-marker-${point.id}`}
            position={[point.lat, point.lng]}
            icon={leafletQuizMarkerIcon}
            className="bg-none marker"
            onClick={() => history.push(`/points/${point.id}`)}
          />
        ))}

      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution={'&copy; <a href=http://osm.org/copyright>OpenStreetMap</a> contributors'}
      ></TileLayer>
    </Map>
  );
}
