import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import useLogin from './../hooks/login.js';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { BiDoorOpen } from 'react-icons/bi';
import Spinner from 'react-bootstrap/Spinner';
import TingelAppLogo from './../img/app-icon-s.png';

function Login(props) {
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [setBegin, _token, loading, _error] = useLogin(mail, password);
  const [logoutRequired, setLogoutRequired] = useState(props.logout);

  useEffect(() => {
    if (logoutRequired) {
      setLogoutRequired(false);
      cookie.remove('token', { path: '/' });
      var u = {
        loggedin: false,
        mail: '',
        token: '',
        mediaToken: '',
        isAdmin: false,
      };
      props.setUser(u);
    }
  }, [logoutRequired, setLogoutRequired]);

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      setBegin(true);
    }
  }

  return (
    <Container>
      <Row>
        <Col sm="12">
          <h1>
            <BiDoorOpen className="icon in-h1" />
            Login
          </h1>
        </Col>
      </Row>
      <Row className="justify-content-center mt-0">
        <Col sm="5" className="justify-content-center d-flex flex-column align-items-center">
          <img src={TingelAppLogo} alt="Tingel App Logo" style={{ maxWidth: 100, maxHeight: 100 }} />
          <p className="mb-0 mt-3">
            <b>Tingel</b>
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center mt-5">
        <Col sm="12" md="5">
          <Card>
            <Card.Header>Login</Card.Header>
            <Card.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={mail}
                    onChange={(event) => setMail(event.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder=""
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Group>
                <Button variant="primary" className="w-100" onClick={() => setBegin(true)} disabled={loading}>
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    'Login'
                  )}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
