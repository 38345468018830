import { useEffect } from 'react';
import Client from '../api.js';
import { betaKeysState } from '../state.js';
import { useRecoilState } from 'recoil';
import useNotification from './notification';

export function useListBetaKeys() {
  const [betaKeysData, setBetaKeysData] = useRecoilState(betaKeysState);
  const [setNotification] = useNotification();

  useEffect(() => {
    if (!betaKeysData.reload) return;
    setBetaKeysData({ ...betaKeysData, loading: true, reload: false });

    Client.listBetaKeys((_status, data) => {
      setBetaKeysData({ ...betaKeysData, betaKeys: data, loading: false, error: null, reload: false });
    }).catch((err) => {
      console.log(err);
      setBetaKeysData({ ...betaKeysData, error: err, loading: false, reload: false });
      setNotification({ text: 'Beim laden der Beta Keys ist ein Fehler aufgetreten.', variant: 'warning' });
    });
  }, [betaKeysData.reload]);

  return [betaKeysData, setBetaKeysData];
}
