import React, { useState, useRef } from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Card from 'react-bootstrap/esm/Card';
import Form from 'react-bootstrap/esm/Form';
import Badge from 'react-bootstrap/Badge';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import AreaSelect from '../components/areaSelect.js';
import CategorySelect from './../components/categorySelect.js';
import Button from 'react-bootstrap/esm/Button';
import { Map, TileLayer, Marker } from 'react-leaflet';
import Control from 'react-leaflet-control';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ProgressBar from 'react-bootstrap/ProgressBar';
import HintList from './../components/hintList.js';
import useInit from '../hooks/init.js';
import { useGetPoint, useUpdatePoint, useDefaultPoint, usePostPoint, useDeletePoint } from '../hooks/point.js';
import useLocation from '../hooks/location.js';
import PointDataCheck from './../components/pointDataCheck';
import {
  BiCurrentLocation,
  BiEdit,
  BiListUl,
  BiMapPin,
  BiWalk,
  BiSearchAlt,
  BiError,
  BiImage,
  BiMessageDots,
} from 'react-icons/bi';
import WaypointMarkerIcon from './../img/waypoint-marker-icon.svg';
import QuizMarkerIcon from './../img/quiz-point-marker-icon.svg';
import Spinner from 'react-bootstrap/Spinner';
import ListGroup from 'react-bootstrap/ListGroup';
import { pointState, authState, uiState } from '../state.js';
import { useRecoilState, useRecoilValue } from 'recoil';
import ImagePool from './../components/imagePool';
import ImageSelect from './../components/imageSelect';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

export default function Point(props) {
  const mapRef = useRef(null);
  const [uiData, setUiData] = useRecoilState(uiState);
  const setMapView = (coords, zoom) => {
    if (!mapRef.current) return;
    mapRef.current.leafletElement.setView(coords, zoom);
  };
  const auth = useRecoilValue(authState);

  useGetPoint(props.match.params.id);
  useInit(() => setPointData({ ...pointData, reload: true }));
  useDefaultPoint(props.match.params.id);

  const [pointData, setPointData] = useRecoilState(pointState);
  const [setDeletePointId] = useDeletePoint();
  const { point } = pointData;
  const pointLoading = pointData.loading;
  const pointError = pointData.error;
  const [setUpdatePointBegin] = useUpdatePoint(point);
  const [setPostPointBegin] = usePostPoint();
  const [selectedStop, setSelectedStop] = useState('waypoint');
  const [setLocationBegin, location, locationLoading, locationError] = useLocation((v) => setMapView(v, 16));

  const leafletWaypointMarkerIcon = new L.Icon({
    iconUrl: WaypointMarkerIcon,
    iconRetinaUrl: WaypointMarkerIcon,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconAnchor: [15, 15],
    iconSize: new L.Point(30, 30),
    className: 'leaflet-div-icon',
  });
  const leafletQuizMarkerIcon = new L.Icon({
    iconUrl: QuizMarkerIcon,
    iconRetinaUrl: QuizMarkerIcon,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconAnchor: [15, 15],
    iconSize: new L.Point(30, 30),
    className: 'leaflet-div-icon',
  });

  const setMapUiData = () => {
    if (mapRef.current) {
      const center = mapRef.current.leafletElement.getCenter();
      setUiData({ ...uiData, mapCenter: [center.lat, center.lng], mapZoom: mapRef.current.leafletElement.getZoom() });
    }
  };

  const mapCenter =
    point && point.lat ? [point.lat, point.lng] : location ? [location.lat, location.lng] : [53.56017, 9.996472];

  if (pointLoading || !point)
    return (
      <Container>
        <Row>
          <Col>
            <h1>
              <BiEdit className="icon in-h1" /> Punkt
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className="justify-content-center d-flex mt-5">
            {pointError ? <p>Beim Laden des Punktes ist ein Fehler aufgetreten.</p> : <Spinner animation="border" />}
          </Col>
        </Row>
      </Container>
    );

  return (
    <Container>
      <Row>
        <Col>
          <h1>
            <BiEdit className="icon in-h1" /> Punkt
          </h1>
        </Col>
      </Row>

      <Row>
        <Col className="mt-5" sm="12" xl="12">
          <Card>
            <Card.Header>
              <BiListUl className="icon in-card-header" /> Stammdaten
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group controlId="points-filter-form">
                  <Container>
                    <Row>
                      <Col md="4">
                        <Form.Label className="mt-3">Öffentlicher Titel</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={point.title}
                          onChange={(e) => setPointData({ ...pointData, point: { ...point, title: e.target.value } })}
                        />
                      </Col>
                      <Col md="4">
                        <Form.Label className="mt-3">Kategorie</Form.Label>
                        <CategorySelect
                          mandatory
                          fullWidth
                          value={point.category}
                          setCategory={(v) => setPointData({ ...pointData, point: { ...point, category: v } })}
                        />
                      </Col>
                      <Col md="4">
                        <Form.Label className="mt-3">Gebiet</Form.Label>
                        <AreaSelect
                          mandatory
                          fullWidth
                          value={point.area}
                          setArea={(v) => setPointData({ ...pointData, point: { ...point, area: v } })}
                        />
                      </Col>
                      <Col md="4">
                        <Form.Label className="mt-3">Level</Form.Label>
                        <Dropdown
                          onSelect={(v) => {
                            parseInt(v) && setPointData({ ...pointData, point: { ...point, level: parseInt(v) } });
                          }}
                        >
                          <Dropdown.Toggle
                            variant="outline-secondary"
                            id="point-level-dropdown"
                            style={{ width: '100%' }}
                          >
                            {point.level}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {new Array(5).fill(0).map((_zero, level) => (
                              <Dropdown.Item
                                key={`point-level-select-item-${level}`}
                                eventKey={level + 1}
                                active={point.level === level + 1}
                              >
                                {level + 1}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <Col sm="12"></Col>
                      <Col md="6">
                        <Form.Label className="mt-3">Trivia über den Ort</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={point.trivia}
                          onChange={(e) => setPointData({ ...pointData, point: { ...point, trivia: e.target.value } })}
                        />
                        <Form.Label className="mt-3">Interne Notizen zum Punkt</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={point.notes}
                          onChange={(e) => setPointData({ ...pointData, point: { ...point, notes: e.target.value } })}
                        />
                      </Col>
                      <Col md="6">
                        <Form.Label className="mt-3">Attribute</Form.Label>
                        <ListGroup>
                          <ListGroup.Item>
                            <Form.Check
                              type="checkbox"
                              label="Zeitlich eingeschränkt zugänglich"
                              checked={point.restrictedAccess}
                              onChange={(e) =>
                                setPointData({
                                  ...pointData,
                                  point: { ...point, restrictedAccess: !point.restrictedAccess },
                                })
                              }
                            />
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <Form.Check
                              type="checkbox"
                              label="Für Sightseeing geeignet"
                              checked={point.sightseeing}
                              onChange={(e) =>
                                setPointData({
                                  ...pointData,
                                  point: { ...point, sightseeing: !point.sightseeing },
                                })
                              }
                            />
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <Form.Check
                              type="checkbox"
                              label="Nicht barrierefrei"
                              checked={point.barrierFree}
                              onChange={(e) =>
                                setPointData({
                                  ...pointData,
                                  point: { ...point, barrierFree: !point.barrierFree },
                                })
                              }
                            />
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <Form.Check
                              type="checkbox"
                              label="Achtung Verkehr!"
                              checked={point.adultsOnly}
                              onChange={(e) =>
                                setPointData({
                                  ...pointData,
                                  point: { ...point, adultsOnly: !point.adultsOnly },
                                })
                              }
                            />
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                    </Row>
                  </Container>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>

        <Col className="mt-5" sm="12" xl="12">
          <Card>
            <Card.Header>
              <BiImage className="icon in-card-header" /> Bilder
            </Card.Header>
            <Card.Body>
              <ImagePool />
            </Card.Body>
          </Card>
        </Col>

        <Col className="mt-5" sm="12" xl="12">
          <Card>
            <Card.Header>
              <BiMapPin className="icon in-card-header" /> Standort
            </Card.Header>
            <Card.Body>
              <Container>
                <Row>
                  <Col>
                    <ButtonGroup className="d-flex justify-content-center w-100">
                      <ToggleButton
                        key={'map-edit-coords-waypoint-btn'}
                        id={'map-edit-coords-waypoint-btn'}
                        type="radio"
                        variant={selectedStop === 'waypoint' ? 'primary' : 'outline-primary'}
                        name="radio"
                        value={false}
                        checked={false}
                        onChange={(e) => setSelectedStop('waypoint')}
                      >
                        Wegpunkt
                      </ToggleButton>
                      <ToggleButton
                        key={'map-edit-coords-quiz-btn'}
                        id={'map-edit-coords-quiz-btn'}
                        type="radio"
                        variant={selectedStop === 'quizpoint' ? 'success' : 'outline-success'}
                        name="radio"
                        value={false}
                        checked={false}
                        onChange={(e) => setSelectedStop('quizpoint')}
                      >
                        Rätselpunkt
                      </ToggleButton>
                    </ButtonGroup>
                    <Map
                      ref={mapRef}
                      center={point.id ? mapCenter : uiData.mapCenter || [53.56017, 9.996472]}
                      zoom={point.id ? 17 : uiData.mapZoom || 11}
                      zoomControl={true}
                      maxZoom={19}
                      attributionControl={true}
                      doubleClickZoom={true}
                      scrollWheelZoom={false}
                      onZoomEnd={() => setMapUiData()}
                      onMoveEnd={() => setMapUiData()}
                      dragging={true}
                      animate={false}
                      easeLinearity={3}
                      attribution=""
                      className="mt-3"
                      style={{ height: '420px', width: '100%', borderRadius: 3, overflow: 'hidden' }}
                      onClick={(e) => {
                        selectedStop === 'waypoint'
                          ? setPointData({
                              ...pointData,
                              point: { ...point, waypointLat: e.latlng.lat, waypointLng: e.latlng.lng },
                            })
                          : setPointData({ ...pointData, point: { ...point, lat: e.latlng.lat, lng: e.latlng.lng } });
                      }}
                    >
                      <Control position="topleft">
                        <div
                          className="leaflet-bar leaflet-custom-layers-control-element bg-light p-1"
                          style={{ marginBottom: 10, cursor: 'pointer' }}
                        >
                          {locationError ? (
                            <BiError style={{ width: 22, height: 22 }} onClick={() => setLocationBegin(true)} />
                          ) : locationLoading ? (
                            <Spinner
                              animation="border"
                              style={{ width: 22, height: 22, margin: 0, padding: 0 }}
                              size="sm"
                            />
                          ) : (
                            <BiCurrentLocation
                              style={{ width: 22, height: 22 }}
                              onClick={() => setLocationBegin(true)}
                            />
                          )}
                        </div>
                      </Control>
                      <Marker
                        position={
                          selectedStop === 'waypoint' ? [point.waypointLat, point.waypointLng] : [point.lat, point.lng]
                        }
                        icon={selectedStop === 'waypoint' ? leafletWaypointMarkerIcon : leafletQuizMarkerIcon}
                        className="bg-none"
                      ></Marker>

                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        // url='https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png'
                        // url="http://c.tile.stamen.com/watercolor/{z}/{x}/{y}.jpg"
                        attribution={'&copy; <a href=http://osm.org/copyright>OpenStreetMap</a> contributors'}
                      ></TileLayer>
                    </Map>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col className="mt-5" sm="12" xl="6">
          <Card>
            <Card.Header>
              <BiWalk className="icon in-card-header" /> Anlaufstelle
            </Card.Header>
            <Card.Body>
              <Container>
                <Row>
                  <Col>
                    <Form>
                      <Form.Group controlId="points-filter-form">
                        <Form.Label className="mt-3">Wegbeschreibung zur Anlaufstelle</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={point.waypointDirections}
                          onChange={(e) =>
                            setPointData({ ...pointData, point: { ...point, waypointDirections: e.target.value } })
                          }
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col>
                    <ImageSelect destination="WAYPOINT" />
                    <HintList
                      hints={pointData.point.wpHintList}
                      setHints={(v) => setPointData({ ...pointData, point: { ...point, wpHintList: v } })}
                    />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
        <Col className="mt-5" sm="12" xl="6">
          <Card>
            <Card.Header>
              <BiSearchAlt className="icon in-card-header" />
              Rätsel
            </Card.Header>
            <Card.Body>
              <Container>
                <Row>
                  <Col>
                    <Form>
                      <Form.Group controlId="points-filter-form">
                        <Form.Label className="mt-3">Rätselfrage</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={point.quizQuestion}
                          onChange={(e) =>
                            setPointData({ ...pointData, point: { ...point, quizQuestion: e.target.value } })
                          }
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form>
                      <Form.Group controlId="points-filter-form">
                        <Form.Label className="mt-3">
                          Antwort auf das Rätsel {point.quizAnswer ? `(${point.quizAnswer.length} Zeichen)` : ''}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          max="256"
                          value={point.quizAnswer}
                          onChange={(e) =>
                            setPointData({ ...pointData, point: { ...point, quizAnswer: e.target.value } })
                          }
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col>
                    <ImageSelect destination="QUIZ" />
                    <HintList
                      hints={pointData.point.quizHintList}
                      setHints={(v) => setPointData({ ...pointData, point: { ...point, quizHintList: v } })}
                    />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col className="mt-5" sm="12" md="6">
          <PointDataCheck />
        </Col>

        {pointData.point.id && (
          <Col className="mt-5" sm="12" md="6">
            <Card>
              <Card.Header>
                <BiMessageDots className="icon in-card-header" />
                Feedback
              </Card.Header>
              <Card.Body>
                <h4 className="mb-3">Antworten</h4>
                {pointData.point.answerHistogram && pointData.point.answerHistogram.length > 0 ? (
                  <div className="mb-5">
                    {pointData.point.answerHistogram.map((v) => (
                      <>
                        <p className="mb-1">
                          <Badge bg="secondary" style={{ marginRight: 8 }}>
                            {v[1]}x
                          </Badge>
                          {v[0].toUpperCase()}
                        </p>
                        <ProgressBar
                          style={{ height: 6 }}
                          striped
                          variant="secondary"
                          className="mb-3"
                          now={(v[1] / pointData.point.answerHistogram[0][1]) * 100}
                          visuallyHidden
                        />
                      </>
                    ))}
                  </div>
                ) : (
                  <p className="mb-5">Noche keine Antworten vorhanden.</p>
                )}

                <h4 className="mb-3">Bewertungen</h4>
                {pointData.point && pointData.point.feedbackData && pointData.point.feedbackData.feedbackAmount > 0 ? (
                  <>
                    <p>
                      <b>Bewertungen:</b> {pointData.point.feedbackData.feedbackAmount}
                      {pointData.point.feedbackData.feedbackRate && (
                        <span>
                          <b>
                            <br />
                            Anteil positiver Bewertungen:
                          </b>{' '}
                          {(pointData.point.feedbackData.feedbackRate * 100).toFixed(0)} %
                        </span>
                      )}
                    </p>
                    <div style={{ maxHeight: 320, overflowY: 'auto' }}>
                      {pointData.point.feedbackData.feedbacks.map((feedback) => {
                        let createdAt = Date.parse(feedback.createdAt);
                        createdAt = new Date(createdAt).toLocaleDateString('en-GB');
                        return (
                          <Card size="sm" className="no-shadow mb-2" key={feedback.id}>
                            <Card.Header>
                              <div className="d-flex justify-content-between">
                                <p className="mb-0">
                                  {feedback.createdByUsername || 'Unbekannt'}{' '}
                                  <span style={{ marginLeft: 4 }}>{feedback.thumbsUpDown ? '👍' : '👎'}</span>
                                </p>{' '}
                                <p className="mb-0" style={{ fontWeight: 400 }}>
                                  <small>{createdAt}</small>
                                </p>
                              </div>
                            </Card.Header>
                            <Card.Body>{feedback.message}</Card.Body>
                          </Card>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <p className="mb-0">Noche keine Bewertungen vorhanden.</p>
                )}
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>

      <Row>
        <hr className="mt-5" />
      </Row>

      {auth.role === 'admin' && (
        <Row>
          <Col className="mt-5" md="4">
            <ToggleButtonGroup type="checkbox" className="w-100">
              <ToggleButton
                size="lg"
                key={`point-publish-btn-publish`}
                variant={point.published ? 'success' : 'outline-success'}
                onClick={() => setPointData({ ...pointData, point: { ...pointData.point, published: true } })}
              >
                Online
              </ToggleButton>
              <ToggleButton
                size="lg"
                key={`point-publish-btn-un-publish`}
                variant={!point.published ? 'dark' : 'outline-dark'}
                onClick={() => setPointData({ ...pointData, point: { ...pointData.point, published: false } })}
              >
                Offline
              </ToggleButton>
            </ToggleButtonGroup>
          </Col>
        </Row>
      )}

      <Row>
        <Col className="mt-5" md="4">
          <Button
            variant="primary"
            size="lg"
            onClick={() => {
              point.id ? setUpdatePointBegin(true) : setPostPointBegin(true);
            }}
            className="full-width"
          >
            {point.id ? 'Speichern' : 'Erstellen'}
          </Button>
        </Col>
        {['admin', 'worker'].includes(auth.role) && point.id && (
          <Col className="mt-5" md="4">
            <Button
              variant="outline-danger"
              size="lg"
              onClick={() => setDeletePointId(props.match.params.id)}
              className="full-width"
            >
              Löschen
            </Button>
          </Col>
        )}
      </Row>
    </Container>
  );
}
