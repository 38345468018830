import { useEffect, useState } from 'react';
import Client from '../api.js';
import { areasState, areaState } from '../state.js';
import { useRecoilState } from 'recoil';
import useNotification from './notification';

export function usePostArea() {
  const [begin, setBegin] = useState(false);
  const [areasData, setAreasData] = useRecoilState(areasState);
  const [areaData, setAreaData] = useRecoilState(areaState);
  const [setNotification] = useNotification();

  useEffect(() => {
    let newAreaData = { ...areaData };

    if (begin) {
      setBegin(false);
      setAreaData({ ...newAreaData, loading: true, reload: false });
      Client.postArea(newAreaData.area, (_status, _data) => {
        setAreaData({ ...areaData, area: null, loading: false, error: null, reload: false });
        setAreasData({ ...areasData, reload: true });
        setNotification({
          text: 'Gebiet erfolgreich erstellt.',
          variant: 'primary',
        });
      }).catch((err) => {
        console.log(err);
        err.response.status === 409
          ? setNotification({
              text: 'Es existiert bereits ein Gebiet mit diesem Titel.',
              variant: 'warning',
            })
          : setNotification({
              text: 'Beim Erstellen des Gebiets ist ein Fehler aufgetreten.',
              variant: 'warning',
            });
        setAreaData({ ...areaData, loading: false, error: err, reload: false });
      });
    }
  }, [begin]);

  return [setBegin];
}

export function useListAreas() {
  const [areasData, setAreasData] = useRecoilState(areasState);
  const [setNotification] = useNotification();

  useEffect(() => {
    if (!areasData.reload) return;
    setAreasData({ ...areasData, loading: true, reload: false });

    Client.listAreas((_, data) => {
      setAreasData({ ...areasData, areas: data, loading: false, error: false, reload: false });
    }).catch((err) => {
      console.log(err);
      setAreasData({ ...areasData, loading: false, error: err, reload: false });
      setNotification({ text: 'Beim Laden der Gebiete ist ein Fehler aufgetreten.', variant: 'warning' });
    });
  }, [areasData.reload]);

  return [areasData, setAreasData];
}

export function useDeleteArea() {
  const [title, setTitle] = useState(null);
  const [areasData, setAreasData] = useRecoilState(areasState);
  const [areaData, setAreaData] = useRecoilState(areaState);
  const [setNotification] = useNotification();

  useEffect(() => {
    let newAreaData = { ...areaData };

    if (title) {
      setTitle(null);
      setAreaData({ ...newAreaData, loading: true, reload: false });

      Client.deleteArea(title, (status, data) => {
        setAreaData({ ...areaData, area: data, loading: false, error: null, reload: false });
        setAreasData({ ...areasData, reload: true });
        status === 204 &&
          setNotification({
            text: 'Gebiet erfolgreich gelöscht.',
            variant: 'primary',
          });
      }).catch((err) => {
        setAreaData({ ...areaData, loading: false, error: err, reload: false });
        setNotification({
          text: 'Beim Löschen des Gebiets ist ein Fehler aufgetreten.',
          variant: 'warning',
        });
      });
    }
  }, [title]);

  return [setTitle];
}

export function useDefaultArea() {
  const [reset, setReset] = useState(false);
  const [_areaData, setAreaData] = useRecoilState(areaState);

  useEffect(() => {
    if (reset) {
      setReset(false);

      setAreaData({
        reload: false,
        area: {
          title: '',
        },
        loading: false,
        error: null,
      });
    }
  }, [reset]);

  return [setReset];
}
