import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { BiUpArrowAlt, BiDownArrowAlt, BiTrash, BiPlusCircle } from 'react-icons/bi';
import Form from 'react-bootstrap/esm/Form';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import SymbolSelect from './symbolSelect.js';
import Dropdown from 'react-bootstrap/esm/Dropdown';

import Button from 'react-bootstrap/esm/Button';

export default function HintList(props) {
  const { setHints, hints } = props;
  const hintTags = ['Vor Text', 'Zwischen Text & Bild', 'Nach Bild'];

  function swapPositions(list, a, b) {
    if (a < 0 || b < 0 || a === b || !list[a] || !list[b]) return;
    let tmp = JSON.parse(JSON.stringify(list[a]));
    list[a] = list[b];
    list[b] = tmp;
    setHints(list);
  }

  return (
    <>
      <h4 className="mb-3 mt-5">Hinweise</h4>
      {hints && hints.length > 0 ? (
        hints.map((hint, n) => (
          <ListGroup key={hint.id || `wp-hint-list-item-${n}`} className="w-100 mt-3">
            <ListGroup.Item className="d-flex align-items-center w-100 flex-column bg-light">
              <Form.Group controlId="points-filter-form" className="w-100">
                <Form.Control
                  type="text"
                  as="textarea"
                  rows="3"
                  value={hint.hint}
                  placeholder="Lorem ipsum dolor"
                  maxLength="1024"
                  className="w-100"
                  onChange={(e) => {
                    let newHints = JSON.parse(JSON.stringify(hints));
                    newHints[n].hint = e.target.value;
                    setHints(newHints);
                  }}
                />
              </Form.Group>
              <div className="w-100 d-flex justify-content-between align-items-baseline flex-wrap">
                <Dropdown
                  className="mt-3"
                  style={{ marginRight: '0.5rem' }}
                  onSelect={(v) => {
                    let newHints = JSON.parse(JSON.stringify(hints));
                    newHints[n].tag = v;
                    setHints(newHints);
                  }}
                >
                  <Dropdown.Toggle variant="light" id={`hint-list-item-hint-placement`}>
                    {hint.tag ? hint.tag : 'Platzierung'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {hintTags.map((tag, n) => (
                      <Dropdown.Item
                        eventKey={tag}
                        active={tag === hint.tag}
                        key={`hint-list-item-hint-placement-${n}`}
                      >
                        {tag}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <ButtonGroup>
                  <SymbolSelect
                    size="sm"
                    value={hint.symbol ? hint.symbol.trim().toLowerCase() : null}
                    onSelect={(v) => {
                      let newHints = JSON.parse(JSON.stringify(hints));
                      newHints[n].symbol = v;
                      setHints(newHints);
                    }}
                  />
                  <Button
                    variant="light"
                    size="sm"
                    disabled={hints.length < 2 || n === 0}
                    onClick={() => {
                      let newHints = JSON.parse(JSON.stringify(hints));
                      swapPositions(newHints, n, n - 1);
                    }}
                  >
                    <BiUpArrowAlt className="icon" />
                  </Button>{' '}
                  <Button
                    variant="light"
                    size="sm"
                    disabled={hints.length < 2 || hints.length <= n + 1}
                    onClick={() => {
                      let newHints = JSON.parse(JSON.stringify(hints));
                      swapPositions(newHints, n, n + 1);
                    }}
                  >
                    <BiDownArrowAlt className="icon" />
                  </Button>
                  <Button variant="light" size="sm" onClick={() => setHints(hints.filter((_v, i) => i !== n))}>
                    <BiTrash className="icon" />
                  </Button>
                </ButtonGroup>
              </div>
            </ListGroup.Item>
          </ListGroup>
        ))
      ) : (
        <p>Keine Hinweise vorhanden.</p>
      )}
      <div className="d-flex justify-content-begin">
        <Button
          variant="light"
          onClick={() => {
            let newHints = [...hints];
            newHints.push({ hint: '', symbol: 'lupe', tag: null });
            setHints(newHints);
          }}
          disabled={hints.length > 24}
          className="mt-3"
        >
          <BiPlusCircle className="icon" /> Hinzufügen
        </Button>
      </div>
    </>
  );
}
