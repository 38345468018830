import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useInterval from './../hooks/interval';
import { notificationState } from '../state.js';
import { useRecoilState } from 'recoil';
import { BiX } from 'react-icons/bi';

export default function Notifications() {
  const [notificationData, setNotificationData] = useRecoilState(notificationState);
  const { notifications } = notificationData;

  useInterval(() => {
    let newNotificationData = { ...notificationData };
    newNotificationData.notifications = newNotificationData.notifications.filter((v, n) => n > 0);
    setNotificationData(newNotificationData);
  }, 5000);

  if (!notifications) return null;

  return (
    <Container className="position-fixed bottom-0" style={{ zIndex: 1000, maxWidth: 320 }}>
      {notifications.map((notification, n) => (
        <Row key={`notification-list-item-${notification.text}-${n}`}>
          <Col>
            <Alert
              variant={notification.variant || 'secondary'}
              className="d-flex justify-content-between align-items-center"
            >
              <p className="mb-0 pb-0">{notification.text || 'Etwas ist passiert, aber ich weiß nicht genau was...'}</p>
              <BiX
                className="icon cursor-pointer"
                onClick={() => {
                  let newNotificationData = { ...notificationData };
                  newNotificationData.notifications = newNotificationData.notifications.filter((v, i) => i !== n);
                  setNotificationData(newNotificationData);
                }}
              />
            </Alert>
          </Col>
        </Row>
      ))}
    </Container>
  );
}
