import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { imgState, pointState } from './../state';
import Client from './../api.js';
import useNotification from './../hooks/notification';

export function usePostImg() {
  const [imgData, setImgData] = useRecoilState(imgState);
  const [pointData, setPointData] = useRecoilState(pointState);
  const [setNotification] = useNotification();

  useEffect(() => {
    if (!imgData.reload || !imgData.img) return;
    setImgData({ ...imgData, loading: true, reload: false });

    const data = new FormData();
    data.append('file', imgData.img, imgData.img.name);

    Client.postImg(data, (status, data) => {
      setImgData({ ...imgData, img: data, loading: false, erro: null, reload: false });
      setNotification({ text: 'Upload erfolgreich.', variant: 'primary' });
      pointData.point &&
        setPointData({
          ...pointData,
          point: {
            ...pointData.point,
            images: pointData.point.images ? [...pointData.point.images, data.fileId] : [data.fileId],
          },
        });
    }).catch((err) => {
      console.log(err);
      setImgData({ ...imgData, loading: false, erro: err, reload: false });
    });
  }, [imgData.reload, imgData.img]);

  return [imgData, setImgData];
}
