import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class LegalNotice extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <h1>Impressum</h1>
            <h2>Angaben gemäß § 5 TMG:</h2>
            <p>
              Jan Flessau
              <br />
              Georg-Sasse-Straße 10
              <br />
              22949 Ammersbek
            </p>
            <h2>Kontakt:</h2>
            <p>
              Telefon: 0175 5950528
              <br />
              E-Mail: jan.jf@me.com
            </p>
            <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h2>
            <p>
              Jan Flessau
              <br />
              Georg-Sasse-Straße 10
              <br />
              22949 Ammersbek
            </p>
            <h2>Streitschlichtung</h2>
            <p>
              Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
              <br />
              <br />
              <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>
              <br />
              <br />
              Unsere E-Mail-Adresse finden Sie oben im Impressum. Wir sind nicht bereit oder verpflichtet, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
            </p>
            <p className="source">Quelle: http://www.e-recht24.de</p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default LegalNotice;
