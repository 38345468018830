import { useEffect } from 'react';
import Client from './../api.js';
import { statisticsState } from './../state.js';
import { useRecoilState } from 'recoil';
import useNotification from './../hooks/notification';

export function useListStatistics() {
  const [statisticsData, setStatisticsData] = useRecoilState(statisticsState);
  const [setNotification] = useNotification();

  useEffect(() => {
    if (!statisticsData.reload) return;
    setStatisticsData({ ...statisticsData, loading: true, reload: false });

    Client.listStatistics(statisticsData.fitler, (_status, data) => {
      setStatisticsData({ ...statisticsData, statistics: data, loading: false, error: null, reload: false });
    }).catch((err) => {
      console.log(err);
      setStatisticsData({ ...statisticsData, error: err, loading: false, reload: false });
      setNotification({ text: 'Beim laden der Statistiken ist ein Fehler aufgetreten.', variant: 'warning' });
    });
  }, [statisticsData.reload]);

  return [statisticsData, setStatisticsData];
}
