import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import Card from 'react-bootstrap/esm/Card';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { capitalCase } from 'change-case';
import { BiEdit } from 'react-icons/bi';
import useInit from './../hooks/init.js';

import Form from 'react-bootstrap/esm/Form';
import { BiUserCircle } from 'react-icons/bi';
import { useGetUser, useUpdateUser, usePostUser, useDefaultUser, useDeleteUser } from '../hooks/user.js';

export default function Users(props) {
  const [userData, setUserData] = useGetUser(props.match.params.id);
  const [setUpdateUserBegin] = useUpdateUser();
  const [setDeleteUserId] = useDeleteUser();
  const [setPostUserBegin] = usePostUser();
  useInit(() => setUserData({ ...userData, reload: true }));
  useDefaultUser(props.match.params.id);
  const user = userData.user;
  const loading = userData.loading;
  const error = userData.error;

  return (
    <Container>
      <Row>
        <Col sm="12" md="6">
          <h1>
            <BiEdit className="icon in-h1" />
            Nutzer
          </h1>
        </Col>
      </Row>
      {!user || loading ? (
        <Row className="mt-5">
          <Col className="justify-content-center d-flex">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      ) : (
        user && (
          <Row>
            <Col sm="12">
              <Card>
                <Card.Header>
                  <BiUserCircle className="icon in-card-header" />
                  Nutzerdaten
                </Card.Header>
                <Card.Body>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Label className="mt-3">Username (3-16 Zeichen)</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={user.username}
                          onChange={(e) =>
                            setUserData({ ...userData, user: { ...userData.user, username: e.target.value } })
                          }
                        />
                        <Form.Label className="mt-3">Email</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={user.mail}
                          onChange={(e) =>
                            setUserData({ ...userData, user: { ...userData.user, mail: e.target.value } })
                          }
                        />
                      </Col>
                      <Col sm="12" md="6">
                        <Form.Label className="mt-3">
                          {user.id ? 'Neues ' : ''}Passwort (mindestens 6 Zeichen)
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={user.password}
                          onChange={(e) =>
                            setUserData({ ...userData, user: { ...userData.user, password: e.target.value } })
                          }
                        />
                        <Form.Label className="mt-3">Rolle</Form.Label>
                        <Dropdown onSelect={(v) => setUserData({ ...userData, user: { ...userData.user, role: v } })}>
                          <Dropdown.Toggle variant="secondary" id={`user-role-select-${user.id}`}>
                            {user.role && user.role.trim() !== '' ? capitalCase(user.role) : 'Rolle auswählen'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              id={`user-role-select-${user.id}-item-worker`}
                              eventKey="worker"
                              active={user.role === 'worker'}
                            >
                              Worker
                            </Dropdown.Item>
                            <Dropdown.Item
                              id={`user-role-select-${user.id}-item-runner`}
                              eventKey="runner"
                              active={user.role === 'runner'}
                            >
                              Runner
                            </Dropdown.Item>
                            <Dropdown.Item
                              id={`user-role-select-${user.id}-item-user`}
                              eventKey="user"
                              active={user.role === 'user'}
                            >
                              User
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </Col>

            <Col sm="12" md="4" className="mt-5">
              <Button
                className="full-width"
                variant="primary"
                size="lg"
                onClick={() => (props.match.params.id ? setUpdateUserBegin(true) : setPostUserBegin(true))}
                disabled={
                  loading ||
                  error ||
                  user.username.length < 3 ||
                  user.username.length > 16 ||
                  !user.mail ||
                  (!user.id && !user.password) ||
                  (user.password && user.password.length < 6) ||
                  (user.mail && user.mail.length < 6)
                }
              >
                Speichern
              </Button>
            </Col>
            {props.match.params.id && (
              <Col sm="12" md="4" className="d-flex justify-content-end mt-5">
                <Button
                  className="full-width"
                  variant="danger"
                  size="lg"
                  onClick={() => setDeleteUserId(props.match.params.id)}
                  disabled={loading || error}
                >
                  Löschen
                </Button>
              </Col>
            )}
          </Row>
        )
      )}
    </Container>
  );
}
