import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Footer(props) {
  const menuItems = [
    {
      link: '/legal-notice',
      label: 'Impressum',
    },
    {
      link: '/privacy-policy',
      label: 'Datenschutz',
    },
    {
      link: '/disclaimer',
      label: 'Haftungsauschluss',
    },
  ];
  return (
    <Container style={{ marginTop: '50%' }}>
      <Row>
        {menuItems.map((i) => (
          <Col key={i.link}>
            <Link className="text-center text-dark text-sm" to={i.link}>
              <p>{i.label}</p>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
