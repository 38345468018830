import React from 'react';
import { useRecoilValue } from 'recoil';
import { pointState } from './../state';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { BiListCheck, BiCheckCircle, BiMessageError, BiErrorAlt } from 'react-icons/bi';

export default function PointDataCheck() {
  const pointData = useRecoilValue(pointState);
  const point = pointData.point;

  const titleOk = point && point.title;
  const categoryOk = point && point.category;
  const areaOk = point && point.area;
  const triviaOk = point && point.trivia;
  const waypointDirectionsOk = point && point.waypointDirections;
  const wpHintListOk = point && point.wpHintList;
  const quizQuestionOk = point && point.quizQuestion;
  const quizHintListOk = point && point.quizHintList;
  const quizAnswerOk = point && point.quizAnswer;

  if (!point) return null;

  return (
    <Card>
      <Card.Header>
        <BiListCheck className="icon in-card-header" />
        Checks
      </Card.Header>
      <Card.Body>
        <ListGroup>
          {titleOk ? (
            <ListGroup.Item>
              <BiCheckCircle className="icon in-list" /> Titel
            </ListGroup.Item>
          ) : (
            <ListGroup.Item variant="danger">
              <BiErrorAlt className="icon in-list" /> Titel
            </ListGroup.Item>
          )}
          {categoryOk ? (
            <ListGroup.Item>
              <BiCheckCircle className="icon in-list" /> Kategorie
            </ListGroup.Item>
          ) : (
            <ListGroup.Item variant="danger">
              <BiErrorAlt className="icon in-list" /> Kategorie
            </ListGroup.Item>
          )}
          {areaOk ? (
            <ListGroup.Item>
              <BiCheckCircle className="icon in-list" /> Gebiet
            </ListGroup.Item>
          ) : (
            <ListGroup.Item variant="danger">
              <BiErrorAlt className="icon in-list" /> Gebiet
            </ListGroup.Item>
          )}
          {triviaOk ? (
            <ListGroup.Item>
              <BiCheckCircle className="icon in-list" /> Trivia
            </ListGroup.Item>
          ) : (
            <ListGroup.Item>
              <BiMessageError className="icon in-list" /> Trivia
            </ListGroup.Item>
          )}
          {waypointDirectionsOk ? (
            <ListGroup.Item>
              <BiCheckCircle className="icon in-list" /> Anlaufstelle: Wegbeschreibung
            </ListGroup.Item>
          ) : (
            <ListGroup.Item variant="danger">
              <BiErrorAlt className="icon in-list" /> Anlaufstelle: Wegbeschreibung
            </ListGroup.Item>
          )}
          {wpHintListOk.length > 0 ? (
            <ListGroup.Item>
              <BiCheckCircle className="icon in-list" /> Anlaufstelle: Hinweise
            </ListGroup.Item>
          ) : (
            <ListGroup.Item>
              <BiMessageError className="icon in-list" /> Anlaufstelle: Hinweise
            </ListGroup.Item>
          )}
          {quizQuestionOk ? (
            <ListGroup.Item>
              <BiCheckCircle className="icon in-list" /> Rätsel: Frage
            </ListGroup.Item>
          ) : (
            <ListGroup.Item variant="danger">
              <BiErrorAlt className="icon in-list" /> Rätsel: Frage
            </ListGroup.Item>
          )}
          {quizHintListOk.length > 0 ? (
            <ListGroup.Item>
              <BiCheckCircle className="icon in-list" /> Rätsel: Hinweise
            </ListGroup.Item>
          ) : (
            <ListGroup.Item>
              <BiMessageError className="icon in-list" /> Rätsel: Hinweise
            </ListGroup.Item>
          )}
          {quizAnswerOk && point.quizAnswer ? (
            <ListGroup.Item>
              <BiCheckCircle className="icon in-list" /> Rätsel: Antwort
            </ListGroup.Item>
          ) : (
            <ListGroup.Item variant="danger">
              <BiErrorAlt className="icon in-list" /> Rätsel: Antwort
            </ListGroup.Item>
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
}
