import { atom, selector } from 'recoil';

export const authState = atom({
  key: 'auth',
  default: {
    token: null,
    userId: null,
    role: null,
    error: null,
    loading: false,
    reload: true,
  },
});

export const pointsFilterState = atom({
  key: 'pointsFilterState',
  default: {
    level: undefined,
    online: true,
    order: 'titleup',
    area: undefined,
    title: undefined,

    coordBounds: undefined,

    limit: 12,
    offset: 0,
  },
});

export const pointsState = atom({
  key: 'pointsState',
  default: {
    reload: true,
    points: [],
    count: 0,
    totalCount: 0,
    loading: false,
    error: null,
  },
});

export const pointsPaginationState = selector({
  key: 'pointsPaginationState',
  get: ({ get }) => {
    const filter = get(pointsFilterState);
    const points = get(pointsState);
    return {
      count: points.count,
      totalCount: points.totalCount,
      limit: filter.limit,
      offset: filter.offset,
    };
  },
});

export const pointState = atom({
  key: 'pointState',
  default: {
    reload: true,
    point: null,
    loading: false,
    error: null,
  },
});

export const notificationState = atom({
  key: 'notificationState',
  default: {
    notifications: [],
  },
});

export const areaState = atom({
  key: 'areaState',
  default: {
    reload: true,
    area: null,
    loading: false,
    error: null,
  },
});

export const areasState = atom({
  key: 'areasState',
  default: {
    reload: true,
    areas: null,
    loading: false,
    error: null,
  },
});

export const categoryState = atom({
  key: 'categoryState',
  default: {
    reload: true,
    category: null,
    loading: false,
    error: null,
  },
});

export const categoriesState = atom({
  key: 'categoriesState',
  default: {
    reload: true,
    categories: null,
    loading: false,
    error: null,
  },
});

export const userState = atom({
  key: 'userState',
  default: {
    reload: true,
    user: null,
    loading: false,
    error: null,
  },
});

export const usersState = atom({
  key: 'usersState',
  default: {
    reload: true,
    users: null,
    loading: false,
    error: null,
  },
});

export const imgState = atom({
  key: 'imgState',
  default: {
    reload: false,
    img: null,
    loading: false,
    error: null,
  },
});

export const uiState = atom({
  key: 'uiState',
  default: {
    mapCenter: [53.56017, 9.996472],
    mapZoom: 11,
    pointListTab: 'LIST',
  },
});

export const swapState = atom({
  key: 'swapState',
  default: {
    reload: true,
    swap: null,
    loading: false,
    error: null,
  },
});

export const swapsState = atom({
  key: 'swapsState',
  default: {
    reload: true,
    swaps: null,
    loading: false,
    error: null,
  },
});

export const betaKeysState = atom({
  key: 'betaKeysState',
  default: {
    reload: true,
    betaKeys: null,
    loading: false,
    error: null,
  },
});

export const statisticsState = atom({
  key: 'statisticsState',
  default: {
    reload: true,
    fitler: {
      online: null,
      level: null,
      area: null,
      category: null,
    },
    statistics: null,
    loading: false,
    error: null,
  },
});
