export const pointSort = {
  updated_at_up: 'Änderungsdatum aufsteigend',
  updated_at_down: 'Änderungsdatum absteigend',
  created_at_up: 'Erstellungsdatum aufsteigend',
  created_at_down: 'Erstellungsdatum absteigend',
  title_up: 'Titel aufsteigend',
  title_down: 'Titel absteigend',
  area_up: 'Gebiet aufsteigend',
  area_down: 'Gebiet absteigend',
  category_up: 'Kategorie aufsteigend',
  category_down: 'Kategorie absteigend',
  level_up: 'Level aufsteigend',
  level_down: 'Level absteigend',
  // online_up: 'Online aufsteigend',
  // online_down: 'Online absteigend',
};

export const symbolEmoji = {
  blume: '🏵',
  kompass: '🧭',
  karte: '🗺️',
  lupe: '🔎',
  fernglas: '🔭',
  glühbirne: '💡',
  farbpalette: '🎨',
  einkaufstasche: '🛍️',
  gluehbirne: '💡',
  koffer: '💼',
  ziegelstein: '🪨',
  speicherkarte: '💾',
};

export const badgeEmoji = {
  default: '🛡️',
};

export const inventoryItemType = {
  currency: 'Währung',
  badge: 'Badge',
  symbol: 'Symbol',
};

export const currencyEmoji = '💎';

export const colors = [
  '#9adedb',
  '#aa9499',
  '#aaf0d1',
  '#b2fba5',
  '#b39eb5',
  '#bdb0d0',
  '#99c5c4',
  '#ffb7ce',
  '#bee7a5',
  '#befd73',
  '#c1c6fc',
  '#c6a4a4',
  '#cb99c9',
  '#77dd77',
  '#ff694f',
  '#ff6961',
  '#ff9899',
  '#fdfd96',
  '#ca9bf7',
  '#836953',
  '#89cff0',
];
