import axios from 'axios';
import conf from './conf/conf.js';
import cookie from 'react-cookies';
import { camelizeKeys, decamelizeKeys } from 'humps';

class Client {
  constructor() {
    let client = axios.create({
      baseURL: conf.apiBase,
    });

    // request middleware
    client.interceptors.request.use(function (config) {
      const token = cookie.load('token');
      config.headers.Authorization = `Bearer ${token}`;

      return config;
    });
    client.interceptors.request.use((config) => {
      const newConfig = { ...config };
      newConfig.url = `${conf.apiBase}${config.url}`;
      if (newConfig.headers['Content-Type'] === 'multipart/form-data') return newConfig;
      if (config.params) {
        newConfig.params = decamelizeKeys(config.params);
      }
      if (config.data) {
        newConfig.data = decamelizeKeys(config.data);
      }
      return newConfig;
    });

    // response middleware
    client.interceptors.response.use(this.handleSuccess, this.handleError);
    client.interceptors.response.use((response) => {
      if (response.data && response.headers['content-type'] === 'application/json') {
        response.data = camelizeKeys(response.data);
      }
      return response;
    });

    this.client = client;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    return Promise.reject(error);
  };

  redirectTo = (document, path) => {
    document.location = path;
  };

  // Auth
  login(body, callback) {
    return this.client.post('auth/login', body).then((response) => callback(response.status, response.data));
  }

  listBetaKeys(callback) {
    return this.client.get('auth/beta_keys').then((response) => callback(response.status, response.data));
  }

  // Point
  postPoint(body, callback) {
    return this.client.post(`points`, body).then((response) => callback(response.status, response.data));
  }

  getPoint(id, callback) {
    return this.client.get(`points/${id}`).then((response) => callback(response.status, response.data));
  }

  listPoints(params, callback) {
    return this.client.get('points', { params }).then((response) => callback(response.status, response.data));
  }

  updatePoint(id, body, callback) {
    return this.client.patch(`points/${id}`, body).then((response) => callback(response.status, response.data));
  }

  deletePoint(id, callback) {
    return this.client.delete(`points/${id}`).then((response) => callback(response.status, response.data));
  }

  // Area
  postArea(body, callback) {
    return this.client.post(`areas`, body).then((response) => callback(response.status, response.data));
  }

  listAreas(callback) {
    return this.client.get('areas').then((response) => callback(response.status, response.data));
  }

  deleteArea(title, callback) {
    return this.client.delete(`areas/${title}`).then((response) => callback(response.status, response.data));
  }

  // Category
  postCategory(body, callback) {
    return this.client.post(`categories`, body).then((response) => callback(response.status, response.data));
  }

  listCategories(callback) {
    return this.client.get('categories').then((response) => callback(response.status, response.data));
  }

  deleteCategory(title, callback) {
    return this.client.delete(`categories/${title}`).then((response) => callback(response.status, response.data));
  }

  // User
  postUser(body, callback) {
    return this.client.post(`users`, body).then((response) => callback(response.status, response.data));
  }

  getUser(id, callback) {
    return this.client.get(`users/${id}`).then((response) => callback(response.status, response.data));
  }

  listUsers(callback) {
    return this.client.get('users').then((response) => callback(response.status, response.data));
  }

  updateUser(id, body, callback) {
    return this.client.patch(`users/${id}`, body).then((response) => callback(response.status, response.data));
  }

  deleteUser(id, callback) {
    return this.client.delete(`users/${id}`).then((response) => callback(response.status, response.data));
  }

  // Swap
  postSwap(body, callback) {
    return this.client.post('swaps', body).then((response) => callback(response.status, response.data));
  }

  listSwaps(callback) {
    return this.client.get('swaps').then((response) => callback(response.status, response.data));
  }

  deleteSwap(id, callback) {
    return this.client.delete(`swaps/${id}`).then((response) => callback(response.status, response.data));
  }

  // Statistic
  listStatistics(params, callback) {
    return this.client.get('statistic', { params }).then((response) => callback(response.status, response.data));
  }

  // Image
  postImg(body, callback) {
    return this.client({
      method: 'post',
      url: 'img',
      data: body,
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then((response) => callback(response.status, response.data));
  }
}

export default new Client();
