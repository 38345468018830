import React, { useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Card from 'react-bootstrap/esm/Card';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { BiShoppingBag } from 'react-icons/bi';
import Form from 'react-bootstrap/esm/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import { swapState } from '../state.js';
import { useRecoilState } from 'recoil';
import { useListSwaps, useDefaultSwap, usePostSwap, useDeleteSwap } from './../hooks/swap';
import { symbolEmoji, badgeEmoji, currencyEmoji, inventoryItemType } from './../schema';

export default function Swaps() {
  const [newSwapFormOpen, setNewSwapFormOpen] = useState(false);
  const [swapsData, _setSwapsData] = useListSwaps();
  const [swapData, setSwapData] = useRecoilState(swapState);
  const [setPostSwapBegin] = usePostSwap();
  const [resetSwapData] = useDefaultSwap();
  const [setDeleteSwapId] = useDeleteSwap();
  const inItemTypeObject =
    swapData.swap &&
    swapData.swap.inItemType !== 'currency' &&
    swapData.swap.inItemName &&
    (swapData.swap.inItemType === 'badge' ? badgeEmoji || null : symbolEmoji || null);
  const outItemTypeObject =
    swapData.swap &&
    swapData.swap.outItemType !== 'currency' &&
    swapData.swap.outItemName &&
    (swapData.swap.outItemType === 'badge' ? badgeEmoji || null : symbolEmoji || null);

  const getItemValue = (itemType, itemName) => {
    switch (itemType) {
      case 'currency': {
        return currencyEmoji;
      }
      case 'symbol': {
        return symbolEmoji[itemName] || '❓';
      }
      case 'badge': {
        return badgeEmoji.default;
      }
      default: {
        return '❓';
      }
    }
  };

  return (
    <Container>
      <Row>
        <Col sm="12" md="6">
          <h1>
            <BiShoppingBag className="icon in-h1" />
            Angebote
          </h1>
        </Col>
        {!newSwapFormOpen && (
          <Col sm="12" md="6" className="justify-content-end d-flex align-items-baseline">
            <Button
              variant="success"
              onClick={() => {
                resetSwapData();
                setNewSwapFormOpen(true);
                resetSwapData(true);
              }}
            >
              Angebot hinzufügen
            </Button>
          </Col>
        )}
      </Row>
      {newSwapFormOpen && swapData.swap && (
        <Row className="mt-5 justify-content-center d-flex">
          <Col sm="12" md="8" lg="5">
            <Card className="w-100">
              <Card.Header>Neues Angebot</Card.Header>
              <Card.Body>
                <Container>
                  <Row>
                    <Col sm="12" md="6" className="mt-3 mb-3">
                      <Form.Label className="">Preis Item Typ</Form.Label>
                      <Dropdown
                        onSelect={(v) => {
                          switch (v) {
                            case 'currency':
                              setSwapData({
                                ...swapData,
                                swap: { ...swapData.swap, inItemName: 'diamond', inItemType: v },
                              });
                              break;
                            case 'symbol':
                              setSwapData({
                                ...swapData,
                                swap: { ...swapData.swap, inItemName: 'lupe', inItemType: v },
                              });
                              break;
                            case 'badge':
                              setSwapData({
                                ...swapData,
                                swap: { ...swapData.swap, inItemName: 'default', inItemType: v },
                              });
                              break;
                          }
                        }}
                      >
                        <Dropdown.Toggle variant="outline-secondary" id="create-swap-in-item-type">
                          {inventoryItemType[swapData.swap.inItemType]}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {Object.keys(inventoryItemType).map((k) => (
                            <Dropdown.Item
                              key={`create-swap-in-item-type-${k}`}
                              eventKey={k}
                              active={k === swapData.swap.inItemType}
                            >
                              {inventoryItemType[k]}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>

                      <Form.Label className="mt-3">Preis Menge</Form.Label>
                      <Dropdown
                        onSelect={(v) =>
                          setSwapData({
                            ...swapData,
                            swap: { ...swapData.swap, inItemAmount: isNaN(parseInt(v)) ? 1 : parseInt(v) },
                          })
                        }
                      >
                        <Dropdown.Toggle variant="outline-secondary" id="create-swap-in-item-amount">
                          {swapData.swap.inItemAmount}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {[0, 1, 2, 3, 4, 5].map((k) => (
                            <Dropdown.Item
                              key={`create-swap-in-item-amount-${k}`}
                              eventKey={k}
                              active={k === swapData.swap.inItemAmount}
                            >
                              {k}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>

                      {inItemTypeObject && (
                        <>
                          <Form.Label className="mt-3">Preis Item</Form.Label>
                          <Dropdown
                            onSelect={(v) => setSwapData({ ...swapData, swap: { ...swapData.swap, inItemName: v } })}
                          >
                            <Dropdown.Toggle variant="outline-secondary" id="create-swap-in-item-name">
                              {inItemTypeObject[swapData.swap.inItemName]}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {Object.keys(inItemTypeObject).map((k) => (
                                <Dropdown.Item
                                  key={`create-swap-in-item-name-${k}`}
                                  eventKey={k}
                                  active={k === swapData.swap.inItemName}
                                >
                                  {inItemTypeObject[k]}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </>
                      )}
                    </Col>
                    <Col sm="12" md="6" className="mt-3 mb-3">
                      <Form.Label>Produkt Item Typ</Form.Label>
                      <Dropdown
                        onSelect={(v) => {
                          switch (v) {
                            case 'currency':
                              setSwapData({
                                ...swapData,
                                swap: { ...swapData.swap, outItemName: 'diamond', outItemType: v },
                              });
                              break;
                            case 'symbol':
                              setSwapData({
                                ...swapData,
                                swap: { ...swapData.swap, outItemName: 'lupe', outItemType: v },
                              });
                              break;
                            case 'badge':
                              setSwapData({
                                ...swapData,
                                swap: { ...swapData.swap, outItemName: 'default', outItemType: v },
                              });
                              break;
                          }
                        }}
                      >
                        <Dropdown.Toggle variant="outline-secondary" id="create-swap-out-item-type">
                          {inventoryItemType[swapData.swap.outItemType]}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {Object.keys(inventoryItemType).map((k) => (
                            <Dropdown.Item
                              key={`create-swap-out-item-type-${k}`}
                              eventKey={k}
                              active={k === swapData.swap.outItemType}
                            >
                              {inventoryItemType[k]}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>

                      <Form.Label className="mt-3">Produkt Menge</Form.Label>
                      <Dropdown
                        onSelect={(v) =>
                          setSwapData({
                            ...swapData,
                            swap: { ...swapData.swap, outItemAmount: isNaN(parseInt(v)) ? 1 : parseInt(v) },
                          })
                        }
                      >
                        <Dropdown.Toggle variant="outline-secondary" id="create-swap-out-item-amount">
                          {swapData.swap.outItemAmount}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {[0, 1, 2, 3, 4, 5].map((k) => (
                            <Dropdown.Item
                              key={`create-swap-in-item-amount-${k}`}
                              eventKey={k}
                              active={k === swapData.swap.outItemAmount}
                            >
                              {k}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>

                      {outItemTypeObject && (
                        <>
                          <Form.Label className="mt-3">Produkt Item</Form.Label>
                          <Dropdown
                            onSelect={(v) => setSwapData({ ...swapData, swap: { ...swapData.swap, outItemName: v } })}
                          >
                            <Dropdown.Toggle variant="outline-secondary" id="create-swap-out-item-name">
                              {outItemTypeObject[swapData.swap.outItemName]}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {Object.keys(outItemTypeObject).map((k) => (
                                <Dropdown.Item
                                  key={`create-swap-out-item-name-${k}`}
                                  eventKey={k}
                                  active={k === swapData.swap.outItemName}
                                >
                                  {outItemTypeObject[k]}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </>
                      )}
                    </Col>
                  </Row>
                </Container>
                <Button
                  variant="success"
                  className="w-100 mt-3"
                  onClick={() => {
                    setPostSwapBegin(true);
                  }}
                  disabled={swapData.loading}
                >
                  Hinzufügen
                </Button>
                <Button
                  variant="secondary"
                  className="w-100 mt-3"
                  onClick={() => {
                    setNewSwapFormOpen(false);
                  }}
                  disabled={swapData.loading}
                >
                  Schließen
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Row>
        <Col className="justify-content-center d-flex mt-5">
          {swapsData.swaps && !swapsData.error && !swapsData.loading && (
            <ListGroup className="w-100">
              {swapsData.swaps.map((swap) => (
                <ListGroup.Item
                  key={`swaps-list-view-item-${swap.id}`}
                  className="d-flex justify-content-between align-items-baseline"
                >
                  <h4 className="mb-0">
                    <Badge pill bg="secondary">
                      {swap.inItemAmount}x
                    </Badge>{' '}
                    <span style={{ marginRight: '1rem' }}>{getItemValue(swap.inItemType, swap.inItemName)}</span>
                    <span style={{ marginRight: '2rem' }}>{' 🔁 '}</span>
                    <span style={{ marginRight: '1rem' }}>
                      <Badge pill bg="primary">
                        {swap.outItemAmount}x
                      </Badge>{' '}
                      {getItemValue(swap.outItemType, swap.outItemName)}
                    </span>
                  </h4>
                  <Button
                    variant="danger"
                    size="sm"
                    disabled={swapData.loading || swapsData.loading}
                    onClick={() => setDeleteSwapId(swap.id)}
                  >
                    Löschen
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Col>
      </Row>
    </Container>
  );
}
