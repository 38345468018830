import React from 'react';
import { Link } from 'react-router-dom';
import {
  BiMap,
  BiGroup,
  BiLock,
  BiMapAlt,
  BiPurchaseTag,
  BiDoorOpen,
  BiShoppingBag,
  BiStats,
  BiKey,
  BiCog,
} from 'react-icons/bi';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import cookie from 'react-cookies';
import { useRecoilState } from 'recoil';
import { authState } from './../state.js';

function Menu() {
  const [auth, setAuth] = useRecoilState(authState);

  const iconStyle = {
    marginBottom: 4,
    marginRight: 4,
  };

  const menuItems = auth.token
    ? [
        ['admin', 'worker', 'runner'].includes(auth.role)
          ? {
              label: 'Punkte',
              link: '/points',
              icon: <BiMap style={iconStyle} />,
            }
          : null,
        ['admin'].includes(auth.role)
          ? {
              label: 'Statistik',
              link: '/statistic',
              icon: <BiStats style={iconStyle} />,
            }
          : null,
        ['admin'].includes(auth.role)
          ? {
              label: 'Einstellungen',
              link: '/settings',
              icon: <BiCog style={iconStyle} />,
              sub: [
                {
                  label: 'Gebiete',
                  link: '/areas',
                  icon: <BiMapAlt style={iconStyle} />,
                },
                {
                  label: 'Kategorien',
                  link: '/categories',
                  icon: <BiPurchaseTag style={iconStyle} />,
                },
                {
                  label: 'Angebote',
                  link: '/swaps',
                  icon: <BiShoppingBag style={iconStyle} />,
                },
                {
                  label: 'Nutzer',
                  link: '/users',
                  icon: <BiGroup style={iconStyle} />,
                },
                {
                  label: 'BetaKeys',
                  link: '/beta-keys',
                  icon: <BiKey style={iconStyle} />,
                },
              ],
            }
          : null,
        {
          label: 'Logout',
          link: '/',
          icon: <BiDoorOpen style={iconStyle} />,
        },
      ]
    : [];

  function logout() {
    cookie.remove('token', { path: '/' });
    setAuth({
      token: null,
      userId: null,
      role: null,
      loading: false,
    });
  }

  return (
    <>
      <Navbar expand="md" variant="dark" bg="primary">
        <Container>
          <Navbar.Brand>Tingel</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {menuItems
                .filter((v) => v !== null)
                .map((i, z) =>
                  !i.sub ? (
                    <Nav.Item key={`${i.label}-${i.link}-${z}`}>
                      <Nav.Link
                        as={Link}
                        to={i.link}
                        href={i.label === 'Logout' ? '' : i.link}
                        onClick={() => {
                          if (i.label === 'Logout') {
                            logout();
                          }
                        }}
                      >
                        {i.icon} {i.label}
                      </Nav.Link>
                    </Nav.Item>
                  ) : (
                    <div key={i.label}>
                      <NavDropdown title={i.label} id="nav-dropdown">
                        {i.sub.map((s, t) => (
                          <NavDropdown.Item
                            as={Link}
                            to={s.link}
                            href={i.link}
                            eventKey={s.link}
                            key={`${i.label}-${s.label}-${s.link}-${t}`}
                          >
                            {s.icon} {s.label}
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    </div>
                  ),
                )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Menu;
