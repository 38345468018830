import { useState, useEffect } from 'react';
import Client from './../api.js';
import { swapState, swapsState } from './../state.js';
import { useRecoilState } from 'recoil';
import useNotification from './../hooks/notification';

export function usePostSwap() {
  const [begin, setBegin] = useState(false);
  const [swapData, setSwapData] = useRecoilState(swapState);
  const [swapsData, setSwapsData] = useRecoilState(swapsState);
  const [setNotification] = useNotification();

  useEffect(() => {
    if (!begin || !swapData.swap) return;
    setBegin(false);
    setSwapData({ ...swapData, loading: true, reload: false });
    Client.postSwap(swapData.swap, (_status, data) => {
      setSwapData({ ...swapData, swap: data, loading: false, error: null, reload: false });
      setSwapsData({ ...swapsData, reload: true });
      setNotification({ text: 'Angebot erfolgreich erstellt.', variant: 'primary' });
    }).catch((err) => {
      console.log(err);
      setSwapData({ ...swapData, loading: false, error: err, reload: false });
      setNotification({ text: 'Fehler beim Erstellen des Angebots.', variant: 'warning' });
    });
  }, [begin]);

  return [setBegin, swapData.swap];
}

export function useListSwaps() {
  const [swapsData, setSwapsData] = useRecoilState(swapsState);
  const [setNotification] = useNotification();

  useEffect(() => {
    if (!swapsData.reload) return;
    setSwapsData({ ...swapsData, loading: true, reload: false });

    Client.listSwaps((_status, data) => {
      setSwapsData({ ...swapsData, swaps: data, loading: false, error: null, reload: false });
    }).catch((err) => {
      console.log(err);
      setSwapsData({ ...swapsData, error: err, loading: false, reload: false });
      setNotification({ text: 'Beim laden der Angebote ist ein Fehler aufgetreten.', variant: 'warning' });
    });
  }, [swapsData.reload]);

  return [swapsData, setSwapsData];
}

export function useDeleteSwap() {
  const [id, setId] = useState(null);
  const [swapsData, setSwapsData] = useRecoilState(swapsState);
  const [swapData, setSwapData] = useRecoilState(swapState);
  const [setNotification] = useNotification();

  useEffect(() => {
    if (!id) return;
    setId(null);
    setSwapData({ ...swapData, loading: true, reload: false });

    Client.deleteSwap(id, (_status, _data) => {
      setSwapData({ ...swapData, loading: false, error: false, reload: false });
      setSwapsData({ ...swapsData, reload: true });
      setNotification({
        text: 'Angebot erfolgreich gelöscht.',
        variant: 'primary',
      });
    }).catch((err) => {
      console.log(err);
      setSwapData({ ...swapData, loading: false, error: err, reload: false });
      setNotification({
        text: 'Fehler beim Löschen des Angebots.',
        variant: 'warning',
      });
    });
  }, [id]);

  return [setId];
}

export function useDefaultSwap() {
  const [reset, setReset] = useState(false);
  const [_swapData, setSwapData] = useRecoilState(swapState);

  useEffect(() => {
    if (reset) {
      setReset(false);

      setSwapData({
        reload: false,
        swap: {
          inItemAmount: 3,
          inItemType: 'currency',
          inItemName: 'diamond',
          outItemAmount: 1,
          outItemType: 'symbol',
          outItemName: 'lupe',
        },
        loading: false,
        error: null,
      });
    }
  }, [reset]);

  return [setReset];
}
