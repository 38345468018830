import { useEffect, useState } from 'react';
import Client from '../api.js';
import { usersState, userState } from '../state.js';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import useNotification from './notification';

export function usePostUser() {
  const history = useHistory();
  const [userData, setUserData] = useRecoilState(userState);
  const [usersData, setUsersData] = useRecoilState(usersState);
  const [begin, setBegin] = useState(false);
  const [setNotification] = useNotification();

  useEffect(() => {
    if (!begin) return;
    setBegin(false);
    setUserData({ ...userData, loading: true, reload: false });

    Client.postUser(userData.user, (status, data) => {
      setUserData({ ...userData, user: data, loading: false, error: null, reload: false });
      setUsersData({ ...usersData, reload: true });
      status === 201 &&
        setNotification({
          text: 'Nutzer erfolgreich erstellt.',
          variant: 'primary',
        });
      history.push(`/users`);
    }).catch((err) => {
      console.log(err);
      setUserData({ ...userData, loading: false, error: err, reload: false });
      setNotification({
        text: 'Beim Erstellen des Nutzers ist ein Fehler aufgetreten.',
        variant: 'warning',
      });
    });
  }, [begin]);

  return [setBegin];
}

export function useGetUser(userId) {
  const [id, _setId] = useState(userId);
  const [userData, setUserData] = useRecoilState(userState);
  const [setNotification] = useNotification();

  useEffect(() => {
    if (!id || !userData.reload) return;
    setUserData({ ...userData, loading: true, reload: false });

    Client.getUser(id, (_, data) => {
      setUserData({ ...userData, user: data, loading: false, error: null, reload: false });
    }).catch((err) => {
      console.log(err);
      setUserData({ ...userData, error: err, loading: false, reload: false });
      setNotification({ text: 'Beim Laden des Nutzers ist ein Fehler aufgetreten.', variant: 'warning' });
    });
  }, [userData, id]);

  return [userData, setUserData];
}

export function useListUsers() {
  const [usersData, setUsersData] = useRecoilState(usersState);
  const [setNotification] = useNotification();

  useEffect(() => {
    if (!usersData.reload) return;
    setUsersData({ ...usersData, loading: true, reload: false });

    Client.listUsers((_, data) => {
      setUsersData({ ...usersData, users: data, loading: false, error: null, reload: false });
    }).catch((err) => {
      console.log(err);
      setUsersData({ ...usersData, error: err, loading: false, reload: false });
      setNotification({ text: 'Beim Laden der Nutzer ist ein Fehler aufgetreten.', variant: 'warning' });
    });
  }, [usersData.reload]);

  return [usersData, setUsersData];
}

export function useUpdateUser() {
  const [begin, setBegin] = useState(false);
  const [userData, setUserData] = useRecoilState(userState);
  const [usersData, setUsersData] = useRecoilState(usersState);
  const [setNotification] = useNotification();

  useEffect(() => {
    if (begin) {
      setBegin(false);
      setUserData({ ...userData, loading: true, reload: false });

      Client.updateUser(userData.user.id, userData.user, (status, _data) => {
        setUserData({ ...userData, loading: false, error: null, reload: true });
        setUsersData({ ...usersData, reload: true });
        status === 200 &&
          setNotification({
            text: 'Nutzer erfolgreich gespeichert.',
            variant: 'primary',
          });
      }).catch((err) => {
        setUserData({ ...userData, loading: false, error: err, reload: false });
        setNotification({
          text: 'Fehler beim Speichern des Nutzers.',
          variant: 'warning',
        });
      });
    }
  }, [begin]);

  return [setBegin];
}

export function useDeleteUser() {
  const [id, setId] = useState(null);
  const [usersData, setUsersData] = useRecoilState(usersState);
  const [userData, setUserData] = useRecoilState(userState);
  const [setNotification] = useNotification();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      setId(null);
      setUserData({ ...userData, loading: true, reload: false });

      Client.deleteUser(id, (_status, _data) => {
        setUserData({ ...userData, user: null, loading: false, error: null, reload: false });
        setUsersData({ ...usersData, reload: true });
        setNotification({
          text: 'Nutzer erfolgreich gelöscht.',
          variant: 'primary',
        });
        history.push(`/users`);
      }).catch((err) => {
        setUserData({ ...userData, loading: false, error: err, reload: false });
        setNotification({
          text: 'Beim Löschen des Nutzers ist ein Fehler aufgetreten.',
          variant: 'warning',
        });
      });
    }
  }, [id]);

  return [setId];
}

export function useDefaultUser(id) {
  const [reset, setReset] = useState(id === undefined);
  const [_userData, setUserData] = useRecoilState(userState);

  useEffect(() => {
    if (reset) {
      setReset(false);
      setUserData({
        reload: false,
        user: {
          role: 'runner',
          username: '',
          mail: '',
          password: '',
        },
        loading: false,
        error: null,
      });
    }
  }, [reset]);
}
