import { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import cookie from 'react-cookies';
import Client from './../api.js';
import { authState } from './../state.js';
import { useRecoilState } from 'recoil';
import useNotification from './../hooks/notification';

export default function useLogin(mail, password) {
  const [begin, setBegin] = useState(false);

  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [_auth, setAuth] = useRecoilState(authState);
  const [setNotification] = useNotification();

  useEffect(() => {
    if (begin) {
      setBegin(false);
      setLoading(true);

      Client.login({ mail, password: password }, (_, data) => {
        const decodedJWT = jwt_decode(data.token);
        let exp = new Date(decodedJWT.exp);
        cookie.save('token', data.token, { path: '/', expires: exp });
        setToken(decodedJWT);
        const auth = {
          token: data.token,
          userId: decodedJWT.sub,
          role: decodedJWT.role,
        };
        setAuth(auth);
      })
        .catch((err) => {
          setError(err);
          setNotification({ text: 'Login fehlgeschlagen.', variant: 'warning' });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [begin, token, setToken]);

  return [setBegin, token, loading, error];
}
