import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useListAreas } from './../hooks/area.js';
import Spinner from 'react-bootstrap/Spinner';

export default function AreaSelect(props) {
  const { fullWidth, value, setArea, selectAll, mandatory } = props;
  const [areaState] = useListAreas();
  const areas = areaState.areas;
  const loading = areaState.loading;
  const error = areaState.error;

  return (
    <Dropdown onSelect={(v) => setArea(v)}>
      <Dropdown.Toggle
        variant={error ? 'danger' : mandatory && !value ? 'outline-danger' : 'outline-secondary'}
        id="area-select-dropdown"
        style={{ width: fullWidth ? '100%' : undefined, minWidth: 120 }}
      >
        {loading ? (
          <Spinner animation="border" role="status" size="sm">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : error ? (
          'Fehler beim Laden der Gebiete '
        ) : (
          value || 'Gebiet'
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {selectAll && (
          <Dropdown.Item key={'area-all'} eventKey={undefined} active={value === undefined}>
            {'alle'}
          </Dropdown.Item>
        )}
        {areas &&
          areas.map((area, n) => (
            <Dropdown.Item key={area.title + n} eventKey={area.title} active={area.title === value}>
              {area.title}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
