import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { authState } from './../state';
import jwt_decode from 'jwt-decode';
import cookie from 'react-cookies';

export default function useAuth() {
  const [auth, setAuth] = useRecoilState(authState);

  useEffect(() => {
    if (!auth.reload) return;
    setAuth({ ...auth, loading: true, error: null, reload: false });
    var token = cookie.load('token');
    if (token) {
      try {
        const decodedJWT = jwt_decode(token);
        setAuth({
          ...auth,
          token: 'token',
          userID: decodedJWT.sub,
          role: decodedJWT.role,
          loading: false,
          error: null,
          reload: false,
        });
      } catch (err) {
        setAuth({
          ...auth,
          token: null,
          userID: null,
          role: null,
          loading: false,
          error: 'Invalid token.',
          reload: false,
        });
      }
    } else {
      setAuth({
        ...auth,
        token: null,
        userID: null,
        role: null,
        loading: false,
        error: 'Token not found.',
        reload: false,
      });
    }
  }, [auth.reload]);

  return [auth];
}
