import React, { useState, useCallback } from 'react';
import conf from './../conf/conf.js';
import Button from 'react-bootstrap/esm/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useRecoilState } from 'recoil';
import { pointState } from '../state.js';
import { usePostImg } from './../hooks/img.js';
import { useDropzone } from 'react-dropzone';

export default function ImagePool() {
  const [pointData, setPointData] = useRecoilState(pointState);
  const images = pointData.point ? pointData.point.images : [];
  const [imgData, setImgData] = usePostImg();
  const [selectedImages, setSelectedImages] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles[0] && setImgData({ ...imgData, img: acceptedFiles[0], reload: true });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="w-100">
      {selectedImages.length > 0 && (
        <Button
          variant="outline-danger"
          size="sm"
          className="mb-3"
          onClick={() => {
            setPointData({
              ...pointData,
              point: { ...pointData.point, images: images.filter((v) => !selectedImages.includes(v)) },
            });
            setSelectedImages([]);
          }}
        >
          {selectedImages.length > 1 ? 'Ausgewählte Bilder löschen' : 'Ausgewähltes Bild löschen'}
        </Button>
      )}
      <div className="d-flex justify-content-start">
        {imgData.loading && (
          <div
            style={{ width: 200, height: 200, marginRight: '1rem' }}
            className="rounded bg-light mb-3 border d-flex justify-content-center align-items-center"
          >
            <Spinner animation="border" style={{ margin: 0, padding: 0 }} size="sm" />
          </div>
        )}
        {images &&
          images.map((image) => (
            <div
              key={`point-img-pool-${image}`}
              style={{
                width: 200,
                height: 200,
                marginRight: '1rem',
                border: selectedImages.includes(image) ? '4px solid blue' : 'none',
              }}
              className="mb-3 a rounded"
            >
              <img
                onClick={() => {
                  if (selectedImages.includes(image)) {
                    let v = [...selectedImages];
                    setSelectedImages(selectedImages.filter((v) => v != image));
                  } else {
                    setSelectedImages([...selectedImages, image]);
                  }
                }}
                className={'w-100 h-100 rounded cursor-pointer'}
                src={`${conf.apiBase}img/${image}-l.jpg`}
              />
            </div>
          ))}
      </div>

      <div {...getRootProps()} className="bg-light border rounded p-3">
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="mb-0">
            Du kannst jetzt loslassen :)
            <br />
            <br />
          </p>
        ) : (
          <p className="m-0 cursor-pointer">
            Ziehe mit der Maus ein Bild in diesen grauen Kasten.
            <br /> Oder klicke auf ihn um Bilder zum Hochladen auszuwählen.
          </p>
        )}
      </div>
    </div>
  );
}
