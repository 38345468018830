import React from 'react';
import PointFilter from './../components/pointFilter.js';
import { BiMap } from 'react-icons/bi';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import { BiCheckCircle, BiNoEntry } from 'react-icons/bi';
import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card';
import { pointsPaginationState, pointsFilterState, pointsState, uiState } from './../state.js';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useListPoints } from './../hooks/point';
import { Link } from 'react-router-dom';
import PointsMap from './../components/pointsMap';

function Points(props) {
  const history = useHistory();
  const [uiData, setUiData] = useRecoilState(uiState);
  const [points, setPoints] = useRecoilState(pointsState);
  const [filter, setFilter] = useRecoilState(pointsFilterState);
  const pagination = useRecoilValue(pointsPaginationState);
  const [pointsData, setPointsData] = useListPoints();
  const [filterData, setFilterData] = useRecoilState(pointsFilterState);
  const tab = uiData.pointListTab;

  const pageSize = pagination.limit;
  const maxPage = Math.ceil(pagination.totalCount / pageSize);
  const currentPage = Math.floor(pagination.offset / pageSize) + 1;

  let pointList = points.points;
  const loading = points.loading;
  const error = points.error;

  function setOffset(offset) {
    setFilter({ ...filter, offset: offset });
    setPoints({ ...points, reload: true });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }

  return (
    <>
      <Container>
        <Row>
          <Col sm="12" md="6">
            <h1>
              <BiMap className="icon in-h1" />
              Punkte
            </h1>
          </Col>
          <Col sm="12" md="6" className="justify-content-end d-flex align-items-baseline">
            <Button variant="success" onClick={() => history.push('/point/new')}>
              Punkt hinzufügen
            </Button>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col sm="12">
            <PointFilter user={props.user} />
          </Col>
          <>
            <Col className="justify-content-center mt-5" sm="12">
              <Card>
                <Card.Header>
                  <Nav
                    variant="tabs"
                    defaultActiveKey="list"
                    onSelect={(v) => {
                      if (v === 'LIST') {
                        setFilterData({
                          ...filterData,
                          neLat: null,
                          neLng: null,
                          swLat: null,
                          swLng: null,
                        });
                        setPointsData({ ...pointsData, reload: true });
                      }
                      setUiData({ ...uiData, pointListTab: v });
                    }}
                  >
                    <Nav.Item>
                      <Nav.Link active={tab === 'LIST'} eventKey="LIST">
                        Liste
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link active={tab === 'MAP'} eventKey="MAP">
                        Karte
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
                <Card.Body style={{ overflowX: 'auto' }}>
                  {pointList && points.totalCount > 0 && !error && tab === 'LIST' && !loading && (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Titel</th>
                          <th>Gebiet</th>
                          <th>Kategorie</th>
                          <th>Level</th>
                          <th>Online</th>
                          <th>Antworten</th>
                          <th>Bewertungen</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pointList.map((p) => (
                          <tr key={p.id}>
                            <td>
                              <Link to={`/points/${p.id}`} className="text-dark">
                                {p.title || 'Unbekannt'}
                              </Link>
                            </td>
                            <td>{p.area}</td>
                            <td>{p.category}</td>
                            <td>{p.level}</td>
                            <td className={p.published ? 'text-success' : 'text-dark'}>
                              {p.published ? <BiCheckCircle className="icon" /> : <BiNoEntry className="icon" />}
                            </td>
                            <td>
                              <p
                                className={`mb-0 ${
                                  p.correctAnswerRate !== null && p.correctAnswerRate <= 0.5 ? 'text-danger' : ''
                                }`}
                              >
                                {p.correctAnswerRate !== null ? `${(p.correctAnswerRate * 100).toFixed(0)}%` : ''}
                                {p.answerAmount > 0 ? ` (${p.answerAmount})` : ''}
                              </p>
                            </td>
                            <td>
                              <p
                                className={`mb-0 ${
                                  p.feedbackData.feedbackAmount > 0 &&
                                  p.feedbackData.feedbackRate &&
                                  p.feedbackData.feedbackRate <= 0.5
                                    ? 'text-danger'
                                    : ''
                                }`}
                              >
                                {p.feedbackData.feedbackAmount > 0 && p.feedbackData.feedbackRate
                                  ? `${(p.feedbackData.feedbackRate * 100).toFixed(0)}% (${
                                      p.feedbackData.feedbackAmount
                                    })`
                                  : ''}
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}

                  {pointList && points.totalCount > 0 && !error && tab === 'LIST' && !loading && (
                    <Pagination className="justify-content-center mt-5 mb-0">
                      <Pagination.First disabled={filter.offset <= 0} onClick={() => setOffset(0)} />
                      {new Array(maxPage).fill(0).map((_p, n) =>
                        maxPage >= 4 ? (
                          n + 1 >= currentPage - 2 && n + 1 <= currentPage + 2 ? (
                            <Pagination.Item
                              key={`points-pagination-item-${n}`}
                              active={currentPage === n + 1}
                              onClick={() => setOffset(n * pageSize)}
                            >
                              {n + 1}
                            </Pagination.Item>
                          ) : null
                        ) : (
                          <Pagination.Item
                            key={`points-pagination-item-${n}`}
                            active={currentPage === n + 1}
                            onClick={() => setOffset(n * pageSize)}
                          >
                            {n + 1}
                          </Pagination.Item>
                        ),
                      )}

                      <Pagination.Last
                        disabled={currentPage >= maxPage}
                        onClick={() => setOffset((maxPage - 1) * pageSize)}
                      />
                    </Pagination>
                  )}
                  {tab === 'LIST' && !points.totalCount && (
                    <Col className="justify-content-center mt-3" sm="12">
                      <p className="text-center text-secondary">Keine passenden Punkte gefunden 😭</p>
                    </Col>
                  )}

                  {tab === 'MAP' && <PointsMap />}
                </Card.Body>
              </Card>
            </Col>
          </>
        </Row>
      </Container>
    </>
  );
}

export default Points;
