import { useEffect, useState } from 'react';
import Client from '../api.js';
import { categoriesState, categoryState } from '../state.js';
import { useRecoilState } from 'recoil';
import useNotification from './notification';

export function usePostCategory() {
  const [begin, setBegin] = useState(false);
  const [categoriesData, setCategoriesData] = useRecoilState(categoriesState);
  const [categoryData, setCategoryData] = useRecoilState(categoryState);
  const [setNotification] = useNotification();

  useEffect(() => {
    let newCategoryData = { ...categoryData };

    if (begin) {
      setBegin(false);
      setCategoryData({ ...newCategoryData, loading: true, reload: false });
      Client.postCategory(newCategoryData.category, (status, _data) => {
        setCategoryData({ ...categoryData, category: null, loading: false, error: null, reload: false });
        setCategoriesData({ ...categoriesData, reload: true });
        status === 201 &&
          setNotification({
            text: 'Kategorie erfolgreich erstellt.',
            variant: 'primary',
          });
      }).catch((err) => {
        console.log(err);
        err.response.status === 409
          ? setNotification({
              text: 'Es existiert bereits eine Kategorie mit diesem Titel.',
              variant: 'warning',
            })
          : setNotification({
              text: 'Beim Erstellen der Kategories ist ein Fehler aufgetreten.',
              variant: 'warning',
            });
        setCategoryData({ ...categoryData, loading: false, error: err, reload: false });
      });
    }
  }, [begin]);

  return [setBegin];
}

export function useListCategories() {
  const [categoriesData, setCategoriesData] = useRecoilState(categoriesState);
  const [setNotification] = useNotification();

  useEffect(() => {
    if (!categoriesData.reload) return;
    setCategoriesData({ ...categoriesData, loading: true, reload: false });

    Client.listCategories((_, data) => {
      setCategoriesData({ ...categoriesData, categories: data, loading: false, error: null, reload: false });
    }).catch((err) => {
      console.log(err);

      setCategoriesData({ ...categoriesData, loading: false, error: err, reload: false });
      setNotification({ text: 'Beim Laden der Kategoriee ist ein Fehler aufgetreten.', variant: 'warning' });
    });
  }, [categoriesData.reload]);

  return [categoriesData, setCategoriesData];
}

export function useDeleteCategory() {
  const [title, setTitle] = useState(null);
  const [categoriesData, setCategoriesData] = useRecoilState(categoriesState);
  const [categoryData, setCategoryData] = useRecoilState(categoryState);
  const [setNotification] = useNotification();

  useEffect(() => {
    let newCategoryData = { ...categoryData };

    if (title) {
      setTitle(null);
      setCategoryData({ ...newCategoryData, loading: true, reload: false });

      Client.deleteCategory(title, (status, data) => {
        setCategoryData({ ...categoryData, category: data, loading: false, error: null, reload: false });
        setCategoriesData({ ...categoriesData, reload: true });
        status === 204 &&
          setNotification({
            text: 'Kategorie erfolgreich gelöscht.',
            variant: 'primary',
          });
      }).catch((err) => {
        setCategoryData({ ...categoryData, loading: false, error: err, reload: false });
        setNotification({
          text: 'Beim Löschen der Kategorie ist ein Fehler aufgetreten.',
          variant: 'warning',
        });
      });
    }
  }, [title]);

  return [setTitle];
}

export function useDefaultCategory() {
  const [reset, setReset] = useState(false);
  const [_categoryData, setCategoryData] = useRecoilState(categoryState);

  useEffect(() => {
    if (reset) {
      setReset(false);

      setCategoryData({
        reload: false,
        category: {
          title: '',
        },
        loading: false,
        error: null,
      });
    }
  }, [reset]);

  return [setReset];
}
