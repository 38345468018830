import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { BiGroup } from 'react-icons/bi';
import { useListUsers } from '../hooks/user.js';
import { capitalCase } from 'change-case';

export default function Users() {
  const [usersData] = useListUsers();
  const users = usersData.users;
  const loading = usersData.loading;
  const listUsersError = usersData.error;

  return (
    <Container>
      <Row>
        <Col sm="12" md="6">
          <h1>
            <BiGroup className="icon in-h1" />
            Nutzer
          </h1>
        </Col>
        <Col sm="12" md="6" className="justify-content-end d-flex align-items-baseline">
          <Button variant="success" as={Link} to="/user/new" disabled={loading}>
            Nutzer hinzufügen
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Row className="mt-5">
          <Col className="justify-content-center d-flex">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      ) : (
        <Row className="mt-5">
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Rolle</th>
                </tr>
              </thead>
              <tbody>
                {users &&
                  users.map((user) => (
                    <tr key={user.id}>
                      <td>
                        <Link to={`/users/${user.id}`} className="text-dark">
                          {user.username.trim() || 'Unbekannt'}
                        </Link>
                      </td>
                      <td>{user.mail}</td>
                      <td>{capitalCase(user.role)}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </Container>
  );
}
