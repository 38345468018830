import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useListBetaKeys } from './../hooks/betaKey';
import useNotification from './../hooks/notification';
import { BiKey } from 'react-icons/bi';

function BetaKeys() {
  const [betaKeysData, setBetaKeysData] = useListBetaKeys();
  const [setNotification] = useNotification();

  return (
    <Container>
      <Row>
        <Col sm="12">
          <h1>
            <BiKey className="icon in-h1" />
            Beta Keys
          </h1>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          {betaKeysData.loading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : betaKeysData.error ? (
            <p className="mb-0">Beim Laden der BetaKeys ist ein Fehler aufgetreten.</p>
          ) : betaKeysData.betaKeys && betaKeysData.betaKeys.length > 0 ? (
            <ListGroup>
              {betaKeysData.betaKeys.map((k) => (
                <ListGroup.Item key={k.key} className="d-flex justify-content-between align-items-center">
                  <p className="mb-0" style={{ marginRight: 8 }}>
                    {k.key}
                  </p>
                  <CopyToClipboard text={k.key}>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => setNotification({ text: 'Text in Zwischenablage kopiert.', variant: 'primary' })}
                    >
                      Key kopieren
                    </Button>
                  </CopyToClipboard>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <p className="mb-0">Keine BetaKeys vorhanden.</p>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default BetaKeys;
