import { notificationState } from '../state.js';
import { useRecoilState } from 'recoil';

export default function useNotification() {
  const [notificationData, setNotificationData] = useRecoilState(notificationState);

  const setNotification = (notification) => {
    if (notification.text && notification.text.length > 0) {
      let newNotificationData = { ...notificationData };
      newNotificationData.notifications = [...newNotificationData.notifications, notification];
      setNotificationData(newNotificationData);
    }
  };

  return [setNotification];
}
