import React from 'react';
import './style/main-2.scss';
import ReactDOM from 'react-dom';
import User from './views/user.js';
import Users from './views/users.js';
import Menu from './components/menu.js';
import Areas from './views/areas.js';
import Login from './views/login.js';
import Point from './views/point.js';
import Points from './views/points.js';
import BetaKeys from './views/betaKeys.js';
import Footer from './components/footer.js';
import Notifications from './components/notifications';
import Categories from './views/categories.js';
import Swaps from './views/swaps.js';
import Statistic from './views/statistic.js';
import LegalNotice from './views/legal/legalNotice.js';
import PrivacyPolicy from './views/legal/privacyPolicy.js';
import Disclaimer from './views/legal/disclaimer.js';
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import useAuth from './hooks/auth.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollUp from './components/scrollUp';

function App(props) {
  const [auth] = useAuth();

  if (auth.loading) {
    return null;
  }

  if (auth.token) {
    return (
      <Router>
        <>
          <ScrollUp />
          <Route render={(props) => <Menu {...props} />} />
          <div className="w-100 mt-4">
            <Route path="/" exact render={(props) => <Points {...props} />} />
            <Route path="/points" exact render={(props) => <Points {...props} />} />
            <Route path="/points/:id" exact render={(props) => <Point {...props} />} />
            <Route path="/point/new" exact render={(props) => <Point {...props} />} />
            <Route path="/users" exact render={(props) => <Users {...props} />} />
            <Route path="/users/:id" exact render={(props) => <User {...props} />} />
            <Route path="/user/new" exact render={(props) => <User {...props} />} />
            <Route path="/areas" exact render={(props) => <Areas {...props} />} />
            <Route path="/categories" exact render={(props) => <Categories {...props} />} />
            <Route path="/swaps" exact render={(props) => <Swaps {...props} />} />
            <Route path="/statistic" exact render={(props) => <Statistic {...props} />} />
            <Route path="/beta-keys" exact render={(props) => <BetaKeys {...props} />} />
            <Route path="/legal-notice" exact render={(props) => <LegalNotice {...props} />} />
            <Route path="/privacy-policy" exact render={(props) => <PrivacyPolicy {...props} />} />
            <Route path="/disclaimer" exact render={(props) => <Disclaimer {...props} />} />
          </div>
          <Footer />
        </>
      </Router>
    );
  } else {
    return (
      <Router>
        <>
          <ScrollUp />
          <Route render={(props) => <Menu {...props} />} />
          <div className="w-100 mt-4">
            <Switch>
              <Route path="/logout" exact render={(props) => <Login {...props} />} />
              <Route path="/legal-notice" exact render={(props) => <LegalNotice {...props} />} />
              <Route path="/privacy-policy" exact render={(props) => <PrivacyPolicy {...props} />} />
              <Route path="/disclaimer" exact render={(props) => <Disclaimer {...props} />} />
              <Route render={(props) => <Login logout={false} {...props} />} />
            </Switch>
          </div>
          <Footer />
        </>
      </Router>
    );
  }
}

ReactDOM.render(
  <RecoilRoot>
    <App />
    <Notifications />
  </RecoilRoot>,
  document.getElementById('root'),
);
