import React from 'react';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { symbolEmoji } from './../schema';

export default function SymbolSelect(props) {
  const { value, onSelect, size } = props;
  console.log(value);
  return (
    <Dropdown
      onSelect={(v) => {
        const r = v === 'keines' ? null : v;
        console.log(r);
        onSelect(r);
      }}
    >
      <Dropdown.Toggle variant="light" id="symbol-select" size={size || 'md'}>
        {value ? symbolEmoji[value] || '?' : 'keines'}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item key={`symbol-select-item-keines`} eventKey={'keines'}>
          {'keines'}
        </Dropdown.Item>
        {Object.keys(symbolEmoji).map((symbol) => {
          return (
            <Dropdown.Item key={`symbol-select-item-${symbol}`} eventKey={symbol}>
              {symbolEmoji[symbol]}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
