import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Doughnut, Bar } from 'react-chartjs-2';
import Spinner from 'react-bootstrap/Spinner';
import { BiStats } from 'react-icons/bi';
import { useListStatistics } from './../hooks/statistic';
import { colors } from './../schema';

function Statistic() {
  const [statisticsData, _] = useListStatistics();
  const statistics = statisticsData.statistics;

  return (
    <Container>
      <Row>
        <Col sm="12">
          <h1>
            <BiStats className="icon in-h1" />
            Statistik
          </h1>
        </Col>
      </Row>
      {false && ( // TODO - Activate once param filters in tingler are ready
        <Row className="mt-5">
          <Col>
            <Card>
              <Card.Header>
                <BiStats className="icon in-card-header" /> Filter
              </Card.Header>
              <Card.Body></Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {statisticsData.loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : statisticsData.error || !statistics ? (
        <p>Beim Laden der Statistik ist ein Fehler aufgetreten.</p>
      ) : (
        <Row>
          <Col md="12" lg="6" className="mt-5">
            <Card>
              <Card.Header>Online vs. Offline</Card.Header>
              <Card.Body>
                <p>
                  <Doughnut
                    width={256}
                    height={256}
                    options={{ maintainAspectRatio: false }}
                    data={{
                      datasets: [
                        {
                          backgroundColor: colors,
                          data: [statistics.pointAmountTotal, statistics.pointAmountOnline],
                        },
                      ],
                      labels: ['Online', 'Offline'],
                    }}
                  />
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md="12" lg="6" className="mt-5">
            <Card>
              <Card.Header>Gebiete</Card.Header>
              <Card.Body>
                <p>
                  <Bar
                    width={256}
                    height={256}
                    options={{ maintainAspectRatio: false }}
                    data={{
                      datasets: [
                        {
                          label: 'Gebiete',
                          backgroundColor: colors,
                          data: statistics.pointAmountPerArea.map((v) => v[1]),
                        },
                      ],
                      labels: statistics.pointAmountPerArea.map((v) => v[0] || 'Unbekannt'),
                    }}
                  />
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md="12" lg="6" className="mt-5">
            <Card>
              <Card.Header>Kategorien</Card.Header>
              <Card.Body>
                <p>
                  <Bar
                    width={256}
                    height={256}
                    options={{ maintainAspectRatio: false }}
                    data={{
                      datasets: [
                        {
                          label: 'Kategorien',
                          backgroundColor: colors,
                          data: statistics.pointAmountPerCategory.map((v) => v[1]),
                        },
                      ],
                      label: 'hi',
                      labels: statistics.pointAmountPerCategory.map((v) => v[0] || 'Unbekannt'),
                    }}
                  />
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md="12" lg="6" className="mt-5">
            <Card>
              <Card.Header>Level</Card.Header>
              <Card.Body>
                <p>
                  <Bar
                    width={256}
                    height={256}
                    options={{ maintainAspectRatio: false }}
                    data={{
                      datasets: [
                        {
                          label: 'Level',
                          backgroundColor: colors,
                          data: statistics.pointAmountPerLevel.map((v) => v[1]),
                        },
                      ],
                      label: 'hi',
                      labels: statistics.pointAmountPerLevel.map((v) => v[0] || 'Unbekannt'),
                    }}
                  />
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md="12" lg="6" className="mt-5">
            <Card>
              <Card.Header>Nutzer</Card.Header>
              <Card.Body>
                <p>
                  <Bar
                    width={256}
                    height={256}
                    options={{ maintainAspectRatio: false }}
                    data={{
                      datasets: [
                        {
                          label: 'Nutzer',
                          backgroundColor: colors,
                          data: statistics.pointAmountPerUser.map((v) => v[1]),
                        },
                      ],
                      label: 'hi',
                      labels: statistics.pointAmountPerUser.map((v) => v[0] || 'Unbekannt'),
                    }}
                  />
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default Statistic;
