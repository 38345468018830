import { useState, useEffect } from 'react';

export default function useInit(callback) {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) return;
    setInitialized(true);
    callback();
  }, [initialized]);

  return [initialized];
}
