import React from 'react';
import { useRecoilState } from 'recoil';
import { pointState } from '../state.js';
import conf from './../conf/conf.js';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

export default function ImageSelect(props) {
  const { destination } = props;
  const [pointData, setPointData] = useRecoilState(pointState);
  const images = pointData.point ? pointData.point.images : [];

  const image =
    destination === 'WAYPOINT'
      ? pointData.point
        ? pointData.point.waypointImg
        : undefined
      : pointData.point
      ? pointData.point.quizImg
      : undefined;

  const pixelationStep =
    destination === 'WAYPOINT'
      ? pointData.point
        ? pointData.point.waypointImgPixelationStep
        : 0
      : pointData.point
      ? pointData.point.quizImgPixelationStep
      : 0;

  const pixelationSteps = [
    [0, 'l'],
    [1, 'm'],
    [2, 's'],
    [3, 'xs'],
  ];

  if (!images || (images && images.length === 0)) {
    return (
      <div className="w-100">
        <h4 className="mb-3">Bild</h4>
        <p className="mb-0">Lade Bilder hoch um sie hier auszuwählen.</p>
      </div>
    );
  }

  return (
    <div className="w-100">
      <h4 className="mb-3">Bild</h4>
      <div className="w-100 rounded border p-3 bg-light">
        {images.map((v) => (
          <img
            key={`image-select-item-${v}`}
            onClick={() => {
              if (v !== image) {
                destination === 'WAYPOINT'
                  ? setPointData({ ...pointData, point: { ...pointData.point, waypointImg: v } })
                  : setPointData({ ...pointData, point: { ...pointData.point, quizImg: v } });
              } else {
                destination === 'WAYPOINT'
                  ? setPointData({ ...pointData, point: { ...pointData.point, waypointImg: null } })
                  : setPointData({ ...pointData, point: { ...pointData.point, quizImg: null } });
              }
            }}
            src={`${conf.apiBase}img/${v}-${image === v ? pixelationSteps[pixelationStep][1] : 'l'}.jpg`}
            className="rounded"
            style={{
              width: 200,
              height: 200,
              marginBottom: '0.5rem',
              marginRight: '0.5rem',
              border: image === v ? '4px solid blue' : 'none',
            }}
          />
        ))}
        {image && (
          <ToggleButtonGroup type="checkbox" className="w-100">
            {pixelationSteps.map(([n, char]) => (
              <ToggleButton
                key={`pixelation-step-toggle-btn-${destination}-${char}`}
                size="sm"
                value={0}
                variant={n === pixelationStep ? 'secondary' : 'light'}
                onClick={() => {
                  destination === 'WAYPOINT'
                    ? setPointData({ ...pointData, point: { ...pointData.point, waypointImgPixelationStep: n } })
                    : setPointData({ ...pointData, point: { ...pointData.point, quizImgPixelationStep: n } });
                }}
              >
                {char.toUpperCase()}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}
      </div>
    </div>
  );
}
