import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/esm/Card';
import Spinner from 'react-bootstrap/Spinner';

import Form from 'react-bootstrap/esm/Form';
import { BiMapAlt } from 'react-icons/bi';
import { useListAreas, useDefaultArea, usePostArea, useDeleteArea } from './../hooks/area';
import { areaState } from '../state.js';
import { useRecoilState } from 'recoil';

export default function Areas() {
  const [setPostAreaBegin] = usePostArea();
  const [areasState, _setAreas] = useListAreas();
  const [resetDefaultArea] = useDefaultArea();
  const [setDeleteAreaTitle] = useDeleteArea();
  const [newAreaFormOpen, setNewAreaFormOpen] = useState(false);
  const [areaData, setAreaData] = useRecoilState(areaState);
  const areas = areasState.areas;
  const areasLoading = areasState.loading;
  const areasError = areasState.error;
  const areaLoading = areaData.loading;
  const areaError = areaData.error;
  const area = areaData.area;

  return (
    <Container>
      <Row>
        <Col sm="12" md="6">
          <h1>
            <BiMapAlt className="icon in-h1" />
            Gebiete
          </h1>
        </Col>
        {!newAreaFormOpen && (
          <Col sm="12" md="6" className="justify-content-end d-flex align-items-baseline">
            <Button
              variant="success"
              onClick={() => {
                resetDefaultArea();
                setNewAreaFormOpen(true);
              }}
              disabled={areasLoading || areaLoading}
            >
              Gebiet hinzufügen
            </Button>
          </Col>
        )}
      </Row>
      {newAreaFormOpen && (
        <Row className="mt-5">
          <Col className="justify-content-center d-flex">
            <Card>
              <Card.Header>Neues Gebiet</Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group controlId="new-area-form">
                    <Form.Label className="mt-3">Titel</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={(area && area.title) || ''}
                      onChange={(e) => setAreaData({ ...areaData, area: { ...area, title: e.target.value } })}
                    />
                  </Form.Group>
                  <Button
                    variant="success"
                    className="w-100 mt-3"
                    onClick={() => {
                      setPostAreaBegin(true);
                    }}
                    disabled={areaLoading || !area || (area && area.title && area.title.length < 1)}
                  >
                    Gebiet hinzufügen
                  </Button>
                  <Button
                    variant="secondary"
                    className="w-100 mt-3"
                    onClick={() => {
                      setNewAreaFormOpen(false);
                    }}
                    disabled={areaLoading}
                  >
                    Schließen
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {areasLoading ? (
        <Row className="mt-5">
          <Col className="justify-content-center d-flex">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      ) : (
        <Row className="mt-5">
          <Col>
            {areas && areas.length > 0 ? (
              <ListGroup>
                {areas.map((area, n) => (
                  <ListGroup.Item
                    key={`area-list-view-item-${area.title}`}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div className="d-flex justify-content-start align-items-center">
                      <p className="mb-0">{area.title}</p>
                      <Badge
                        pill
                        bg={area.assignedPointsAmount < 1 ? 'light' : 'primary'}
                        className={area.assignedPointsAmount < 1 ? 'text-dark' : 'text-light'}
                        style={{ marginLeft: 16 }}
                      >
                        {area.assignedPointsAmount} {area.assignedPointsAmount === 1 ? 'Punkt' : 'Punkte'}
                      </Badge>
                    </div>
                    {area.assignedPointsAmount > 0 ? null : (
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => setDeleteAreaTitle(area.title)}
                        disabled={areasLoading || areaLoading}
                      >
                        Löschen
                      </Button>
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <p>Keine Gebiete gefunden.</p>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
}
