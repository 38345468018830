import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/esm/Card';
import Spinner from 'react-bootstrap/Spinner';

import Form from 'react-bootstrap/esm/Form';
import { BiPurchaseTag } from 'react-icons/bi';
import { useListCategories, useDefaultCategory, usePostCategory, useDeleteCategory } from '../hooks/category';
import { categoryState } from '../state.js';
import { useRecoilState } from 'recoil';

export default function Categories() {
  const [setPostCategoryBegin] = usePostCategory();
  const [categoriesState, _setCategories] = useListCategories();
  const [resetDefaultCategory] = useDefaultCategory();
  const [setDeleteCategoryTitle] = useDeleteCategory();
  const [newCategoryFormOpen, setNewCategoryFormOpen] = useState(false);
  const [categoryData, setCategoryData] = useRecoilState(categoryState);
  const categories = categoriesState.categories;
  const categoriesLoading = categoriesState.loading;
  const categoriesError = categoriesState.error;
  const categoryLoading = categoryData.loading;
  const categoryError = categoryData.error;
  const category = categoryData.category;

  return (
    <Container>
      <Row>
        <Col sm="12" md="6">
          <h1>
            <BiPurchaseTag className="icon in-h1" />
            Kategorien
          </h1>
        </Col>
        {!newCategoryFormOpen && (
          <Col sm="12" md="6" className="justify-content-end d-flex align-items-baseline">
            <Button
              variant="success"
              onClick={() => {
                resetDefaultCategory();
                setNewCategoryFormOpen(true);
              }}
              disabled={categoriesLoading || categoryLoading}
            >
              Kategorie hinzufügen
            </Button>
          </Col>
        )}
      </Row>
      {newCategoryFormOpen && (
        <Row className="mt-5">
          <Col className="justify-content-center d-flex">
            <Card>
              <Card.Header>Neues Kategorie</Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group controlId="new-category-form">
                    <Form.Label className="mt-3">Titel</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={(category && category.title) || ''}
                      onChange={(e) =>
                        setCategoryData({ ...categoryData, category: { ...category, title: e.target.value } })
                      }
                    />
                  </Form.Group>
                  <Button
                    variant="success"
                    className="w-100 mt-3"
                    onClick={() => {
                      setPostCategoryBegin(true);
                    }}
                    disabled={categoryLoading || !category || (category && category.title && category.title.length < 1)}
                  >
                    Kategorie hinzufügen
                  </Button>
                  <Button
                    variant="secondary"
                    className="w-100 mt-3"
                    onClick={() => {
                      setNewCategoryFormOpen(false);
                    }}
                    disabled={categoryLoading}
                  >
                    Schließen
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {categoriesLoading ? (
        <Row className="mt-5">
          <Col className="justify-content-center d-flex">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      ) : (
        <Row className="mt-5">
          <Col>
            {categories && categories.length > 0 ? (
              <ListGroup>
                {categories.map((category, n) => (
                  <ListGroup.Item
                    key={`category-list-view-item-${category.title}`}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div className="d-flex justify-content-start align-items-center">
                      <p className="mb-0">{category.title}</p>
                      <Badge
                        pill
                        bg={category.assignedPointsAmount < 1 ? 'light' : 'primary'}
                        className={category.assignedPointsAmount < 1 ? 'text-dark' : 'text-light'}
                        style={{ marginLeft: 16 }}
                      >
                        {category.assignedPointsAmount} {category.assignedPointsAmount === 1 ? 'Punkt' : 'Punkte'}
                      </Badge>
                    </div>
                    {category.assignedPointsAmount > 0 ? (
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id={`category-list-view-item-button-tooltip-${category.title}`}>
                            Solange Punkte der Kategorie zugewiesen sind, kann sie nicht gelöscht werden.
                          </Tooltip>
                        }
                      >
                        <div style={{ display: 'inline-block' }}>
                          <Button variant="danger" size="sm" disabled>
                            Löschen
                          </Button>
                        </div>
                      </OverlayTrigger>
                    ) : (
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => setDeleteCategoryTitle(category.title)}
                        disabled={categoriesLoading || categoryLoading}
                      >
                        Löschen
                      </Button>
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <p>Keine Kategorien gefunden.</p>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
}
