import { useEffect, useState } from 'react';
import Client from '../api.js';
import { pointState, pointsFilterState, pointsState } from '../state.js';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import useNotification from './../hooks/notification';

export function usePostPoint() {
  const history = useHistory();
  const [begin, setBegin] = useState(false);
  const [pointData, setPointData] = useRecoilState(pointState);
  const [pointsData, setPointsData] = useRecoilState(pointsState);
  const [setNotification] = useNotification();

  useEffect(() => {
    if (begin) {
      setBegin(false);
      setPointData({ ...pointData, loading: true, reload: false });
      Client.postPoint(pointData.point, (status, data) => {
        setPointData({ ...pointData, point: data, loading: false, error: null, reload: true });
        setPointsData({ ...pointsData, reload: true });
        status === 201 &&
          setNotification({
            text: 'Punkt erfolgreich erstellt.',
            variant: 'primary',
          });
        history.push(`/points/${data.id}`);
      }).catch((err) => {
        setPointData({ ...pointData, loading: false, error: err, reload: false });
        setNotification({
          text: 'Beim Erstellen des Punktes ist ein Fehler aufgetreten. Prüfe ob du alle notwendigen Felder ausgefüllt hast.',
          variant: 'warning',
        });
      });
    }
  }, [begin]);

  return [setBegin];
}

export function useGetPoint(id) {
  const [pointData, setPointData] = useRecoilState(pointState);
  const [setNotification] = useNotification();

  useEffect(() => {
    if (!id || !pointData.reload || pointData.loading) return;
    setPointData({ ...pointData, loading: true, reload: false });

    Client.getPoint(id, (_, data) => {
      setPointData({ ...pointData, point: data, loading: false, error: null, reload: false });
    }).catch((err) => {
      console.log(err);
      setPointData({ ...pointData, error: err, loading: false, reload: false });
      setNotification({ text: 'Beim Laden des Punktes ist ein Fehler aufgetreten.', variant: 'warning' });
    });
  }, [pointData.reload, id]);

  return [pointData, setPointData];
}

export function useListPoints() {
  const [pointsData, setPointsData] = useRecoilState(pointsState);
  const [setNotification] = useNotification();
  const filter = useRecoilValue(pointsFilterState);

  useEffect(() => {
    if (!pointsData.reload) return;
    setPointsData({ ...pointsData, loading: true, reload: false });

    let f = { ...filter };

    Client.listPoints(f, (_, data) => {
      setPointsData({
        ...pointsData,
        points: data.points,
        totalCount: data.totalCount,
        count: data.count,
        loading: false,
        error: null,
        reload: false,
      });
    }).catch((err) => {
      console.log(err);
      setPointsData({ ...pointsData, loading: false, error: err, reload: false });
      setNotification({ text: 'Beim Laden der Punkte ist ein Fehler aufgetreten.', variant: 'warning' });
    });
  }, [pointsData.reload]);

  return [pointsData, setPointsData];
}

export function useUpdatePoint() {
  const [begin, setBegin] = useState(false);
  const [pointData, setPointData] = useRecoilState(pointState);
  const [pointsData, setPointsData] = useRecoilState(pointsState);
  const point = pointData.point;
  const [setNotification] = useNotification();

  useEffect(() => {
    let newPointData = { ...pointData };
    if (begin) {
      setBegin(false);
      setPointData({ ...newPointData, loading: true, reload: false });
      Client.updatePoint(point.id, point, (status, data) => {
        setPointData({ ...pointData, point: data, loading: false, error: null, reload: false });
        setPointsData({ ...pointsData, reload: true });
        status === 200 &&
          setNotification({
            text: 'Punkt erfolgreich gespeichert.',
            variant: 'primary',
          });
      }).catch((err) => {
        setPointData({ ...pointData, loading: false, error: err, reload: false });
        setNotification({
          text: 'Fehler beim Speichern des Punktes. Prüfe ob du alle notwendigen Felder ausgefüllt hast.',
          variant: 'warning',
        });
      });
    }
  }, [begin]);

  return [setBegin];
}

export function useDeletePoint() {
  const [id, setId] = useState(null);
  const [pointData, setPointData] = useRecoilState(pointState);
  const [pointsData, setPointsData] = useRecoilState(pointsState);
  const [setNotification] = useNotification();
  const history = useHistory();

  useEffect(() => {
    if (!id) return;
    setId(null);
    setPointData({ ...pointData, loading: true, reload: false });

    Client.deletePoint(id, (_status, _data) => {
      setPointData({ ...pointData, point: null, loading: false, error: false, reload: false });
      setPointsData({ ...pointsData, reload: true });
      setNotification({
        text: 'Punkt erfolgreich gelöscht.',
        variant: 'primary',
      });
      history.push(`/points`);
    }).catch((err) => {
      setPointData({ ...pointData, loading: false, error: err, reload: false });
      setNotification({
        text: 'Beim Löschen des Punktes ist ein Fehler aufgetreten.',
        variant: 'warning',
      });
    });
  }, [id]);

  return [setId];
}

export function useDefaultPoint(id) {
  const [reset, setReset] = useState(id === undefined);
  const [_pointData, setPointData] = useRecoilState(pointState);

  useEffect(() => {
    if (reset) {
      setReset(false);

      setPointData({
        reload: false,
        point: {
          title: '',
          area: undefined,
          lat: 53.0,
          lng: 10.0,
          images: [],
          waypointLat: 53.0,
          waypointLng: 10.0,
          waypointDirections: '',
          waypointImg: null,
          waypointImgPixelationStep: 0,
          wpHintList: [],
          quizQuestion: '',
          quizImg: null,
          quizImgPixelationStep: 0,
          quizHintList: [],
          quizAnswer: '',
          level: 1,
          category: undefined,
          restrictedAccess: false,
          barrierFree: false,
          adultsOnly: false,
          sightseeing: false,
          published: false,
          state: '',
          notes: '',
          trivia: '',
        },
        loading: false,
        error: null,
      });
    }
  }, [reset]);
}
