import { useState, useEffect } from 'react';

export default function useLocation(callBack) {
  const [begin, setBegin] = useState(false);
  const [apiOk, setApiOk] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [location, setLocation] = useState(undefined);

  useEffect(() => {
    if (!begin && apiOk !== undefined) return;
    if (apiOk === undefined) {
      setApiOk('geolocation' in navigator);
      return;
    }

    if (apiOk === true && begin) {
      const getPosition = (options) => {
        return new Promise(function (resolve, reject) {
          navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
      };

      setLoading(true);

      getPosition()
        .then((position) => {
          callBack([position.coords.latitude, position.coords.longitude]);
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        })
        .catch((err) => {
          console.error(err);
          setError(err);
          setLocation(undefined);
          setLoading(false);
          setBegin(false);
        })
        .finally(() => {
          setLoading(false);
          setBegin(false);
        });
    }
  }, [begin, apiOk, loading]);

  return [setBegin, location, loading, error];
}
